import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import TradingControls from './TradingControls';
import BottomBar from './BottomBar';
import { DesktopCharts } from '../Charts/DesktopCharts';
import { set } from 'mongoose';

const drawerWidth = 240;

const MainContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
});

const ContentContainer = styled('div')({
    display: 'flex',
    flexGrow: 1,
    marginTop: '64px', // Adjust this value based on the height of your navbar
    paddingLeft: drawerWidth,
    paddingRight: drawerWidth,
    paddingBottom: '40px', // Ensure content is not hidden behind the bottom bar
});

const Content = styled('main')({
    flexGrow: 1,
    padding: (theme) => theme.spacing(3),
    overflowY: 'auto', // Allow scrolling for content
});

const FixedSidebarContainer = styled(Box)({
    position: 'fixed',
    top: '64px', // Height of the navbar
    left: 0,
    width: drawerWidth,
    height: 'calc(100% - 104px)', // Ensure it fills the height of the screen below the navbar and above the bottom bar
    backgroundColor: '#1c1c1e',
    color: 'white',
    overflowY: 'auto', // Allow scrolling if content overflows
    zIndex: 1200, // Ensure it appears above other content
});

const FixedTradingContainer = styled(Box)({
    position: 'fixed',
    top: '64px', // Height of the navbar
    right: 0,
    width: drawerWidth,
    height: 'calc(100% - 104px)', // Ensure it fills the height of the screen below the navbar and above the bottom bar
    backgroundColor: '#1c1c1e',
    color: 'white',
    overflowY: 'auto', // Allow scrolling if content overflows
    zIndex: 1200, // Ensure it appears above other content
});

const ChartContainer = styled(Paper)({
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 460px)',
    width: 'calc(100vw - 520px)',

});



function Desktop({ OHLC, Update, CurrentPair, AccountDetails, ...props }) {
    /*---------------QUOTE--------------- */
    const [Quote, setQuote] = useState([]);
    /*----------------END---------------- */

    useEffect(() => {
        const updatedData = props.Quote;
        const processedQuotes = updatedData && typeof updatedData === 'object' ? Object.entries(updatedData)
            .map(([symbol, details]) => ({
                Symbol: symbol,
                ...details // Spread the rest of the details (bid, ask, mid)
            }))
            .filter(quote => quote.bid !== 0 && quote.ask !== 0 && quote.mid !== 0) : []; // Filter out quotes with all zeroes
        setQuote(processedQuotes); // Update the quotes state
    }, [props.Quote]);
    /*-------------------------------------------------END------------------------------------------------------------ */

    const [newCurrency,setNewCurrency]=useState('EURUSD')
    const handleAssetClick = (asset) => {
        setNewCurrency(asset.Symbol)
    };
    return (
        <MainContainer>
            <Navbar Update={Update}/>
            <ContentContainer>
                <ChartContainer>
                    <DesktopCharts CurrentPair={newCurrency} Update={Update} OHLC={OHLC} />
                </ChartContainer>
                <FixedTradingContainer>
                    <TradingControls CurrentPair={newCurrency} Update={Update} OHLC={OHLC}/>
                </FixedTradingContainer>
                <FixedSidebarContainer>
                    <Sidebar assets={Quote} onAssetClick={handleAssetClick} />
                </FixedSidebarContainer>
            </ContentContainer>
            <BottomBar Update={Update} AccountDetails={AccountDetails} />
        </MainContainer>
    );
}

export default Desktop;
