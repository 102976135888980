import React, { useState } from 'react';
import { Box, Tabs, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { formatToMoney } from '../utils/utils'
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { Alert } from '@mui/material';
import Localbase from 'localbase';

const StyledTableCell = styled(TableCell)({
    color: 'white',
    padding: '5px', // Remove padding
    fontSize: '0.7rem', // Reduced font size
});

const StyledTableRow = styled(TableRow)({
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
        backgroundColor: 'transparent', // Transparent background
    },
    '&:nth-of-type(even)': {
        backgroundColor: 'transparent', // Transparent background
    },

});
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
};
const CustomTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        backgroundColor: '#FFD700', // Gold indicator for the selected tab
    },

});

const CustomTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
    color: 'white', // Default color for unselected tabs
    fontSize: '0.6rem', // Reduced font size
    minWidth: '50px', // Reduced minimum width
    '&.Mui-selected': {
        color: '#FFD700', // Gold color for the selected tab
    },
    padding: "0px",
}));
const Positions = ({ openPositions, pendingPositions, accountId }) => {
    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);

        const options = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
        return date.toLocaleString('en-US', options);
    };

    let url;
    if (process.env.NODE_ENV === 'production') {
        url = "https://multitradefx-58ae1ffb4e56.herokuapp.com";
    } else {
        // URL = "http://localhost:3000";
        url = "https://multitradefx-58ae1ffb4e56.herokuapp.com";
    }
    /*----------NOTIFIES IF POSITION ClOSED---------- */
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('info');

    const handleClick = (msg, sev) => {
        setMessage(msg);
        setSeverity(sev);
        setOpen(true);
    };

    React.useEffect(() => {
        if (open) {
            const timer = setTimeout(() => {
                setOpen(false);
            }, 1000); // 3 seconds

            return () => clearTimeout(timer);
        }
    }, [open]);
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };
    /*----------TOTAL PROFIT---------- */
    const [TotalPnL, setTotalPnL] = React.useState(0)
    /*----------END---------- */
    React.useEffect(() => {
        let state_Profit = 0;
        if (openPositions !== null && openPositions.length !== 0) {
            openPositions.map(i => {
                state_Profit += Number(i.profit)
            })
        }
        setTotalPnL(state_Profit)

    }, [openPositions]);
    const [hoveredRow, setHoveredRow] = useState(null)
    const [loading, setLoading] = useState(false)
    const handleClosePosition = async (positionId) => {
        const close_position = `${url}/api/OrderClose`;
        const payload = {
            accountId: accountId,
            positionId: positionId,
        };

        try {
            setLoading(true)
            const response = await axios.post(close_position, payload);
            if (response.data.success) {
                handleClick('Position Closed successfully!', 'success');
            } else {
                handleClick('Failed to close position!', 'error');
            }
        } catch (error) {
            handleClick('An error occurred while closing the position!', 'error');
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    };

    const handleClosePending = async (positionId) => {
        const close_pending = `${url}/api/close-pending-orders`;
        const payload = {
            accountId: accountId,
            positionId: positionId,
        };

        try {
            setLoading(true)
            const response = await axios.post(close_pending, payload);
            if (response.data.success) {
                handleClick('Position Closed successfully!', 'success');
            } else {
                handleClick('Failed to close position!', 'error');
            }
        } catch (error) {
            handleClick('An error occurred while closing the position!', 'error');
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    };

    let db = new Localbase('MultiverseFunding');
    const [closedPositions, setClosedPositions] = React.useState([]);
    const [reload, setReload] = React.useState(0);
    React.useEffect(() => {
        db.collection('tradeAccount').get().then(users => {
            if (users.length !== 0) {
                axios.post(`${url}/api/get-history`, { accountId: users[0].id })
                    .then(res => {
                        setClosedPositions(res.data.history.tradingHistory);
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        });
    }, [reload]);
    function getRandomNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    return (
        <Box style={{ width: "100%" }}>
            <CustomTabs value={tabIndex} onChange={handleTabChange}>
                <CustomTab label="Open" />
                <CustomTab label="Pending" />
                <CustomTab onClick={() => {
                    setReload(getRandomNumber(1, 100))
                }} label="Closed" />
            </CustomTabs>
            <TabPanel value={tabIndex} index={0} >
                <TableContainer component={Paper} style={{ backgroundColor: 'transparent', maxHeight: '300px', overflowY: 'auto' }}>
                    <Table >
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Symbol</StyledTableCell>
                                <StyledTableCell>Type</StyledTableCell>
                                <StyledTableCell>Volume</StyledTableCell>
                                <StyledTableCell>Open Price</StyledTableCell>
                                <StyledTableCell>Close Price</StyledTableCell>
                                <StyledTableCell>T/P</StyledTableCell>
                                <StyledTableCell>S/L</StyledTableCell>
                                <StyledTableCell>Order</StyledTableCell>
                                <StyledTableCell>Open Time</StyledTableCell>
                                <StyledTableCell>Close Time</StyledTableCell>
                                <StyledTableCell>Commission</StyledTableCell>
                                <StyledTableCell>P/L</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {openPositions.map((position, index) => (
                                <StyledTableRow key={index}
                                    onMouseEnter={() => setHoveredRow(index)}
                                    onMouseLeave={() => setHoveredRow(null)}
                                >
                                    <StyledTableCell>{position.symbol}</StyledTableCell>
                                    <StyledTableCell>{position.action}</StyledTableCell>
                                    <StyledTableCell>{position.lot}</StyledTableCell>
                                    <StyledTableCell>{Number(position.entryPrice)}</StyledTableCell>
                                    <StyledTableCell>-</StyledTableCell>
                                    <StyledTableCell>{position.takeProfit}</StyledTableCell>
                                    <StyledTableCell>{position.stopLoss}</StyledTableCell>
                                    <StyledTableCell>{position.positionId}</StyledTableCell>
                                    <StyledTableCell>{formatTimestamp(position.timestamp)}</StyledTableCell>
                                    <StyledTableCell>-</StyledTableCell>
                                    <StyledTableCell>{position.lot * 5}</StyledTableCell>
                                    <StyledTableCell style={{ color: position.profit >= 0 ? '#0376bc' : '#d84027' }}>
                                        {formatToMoney(position.profit)}
                                        {hoveredRow === index && !loading && (
                                            <CloseIcon
                                                style={{ cursor: 'pointer', color: 'white', fontSize: '14px' }}
                                                onClick={() => handleClosePosition(position.positionId)}
                                            />
                                        )}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                            <StyledTableRow>
                                <StyledTableCell>Total P/L</StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell style={{ color: TotalPnL >= 0 ? '#0376bc' : '#d84027' }}>
                                    {formatToMoney(TotalPnL)} USD
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                <TableContainer component={Paper} style={{ backgroundColor: 'transparent', maxHeight: '300px', overflowY: 'auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Symbol</StyledTableCell>

                                <StyledTableCell>Open Price</StyledTableCell>
                                <StyledTableCell>T/P</StyledTableCell>
                                <StyledTableCell>S/L</StyledTableCell>
                                <StyledTableCell>Set Time</StyledTableCell>
                                <StyledTableCell>Order</StyledTableCell>
                                <StyledTableCell>Type</StyledTableCell>
                                <StyledTableCell>Volume</StyledTableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pendingPositions.map((position, index) => (
                                <StyledTableRow key={index}
                                    onMouseEnter={() => setHoveredRow(index)}
                                    onMouseLeave={() => setHoveredRow(null)}
                                >
                                    <StyledTableCell>{position.symbol}</StyledTableCell>
                                    <StyledTableCell>{Number(position.entryPrice)}</StyledTableCell>
                                    <StyledTableCell>{position.takeProfit}</StyledTableCell>
                                    <StyledTableCell>{position.stopLoss}</StyledTableCell>
                                    <StyledTableCell>{formatTimestamp(position.timestamp)}
                                    </StyledTableCell>
                                    <StyledTableCell>{position.positionId}</StyledTableCell>
                                    <StyledTableCell>{position.action}</StyledTableCell>
                                    <StyledTableCell>{position.lot}
                                        {hoveredRow === index && !loading && (
                                            <CloseIcon
                                                style={{ cursor: 'pointer', color: 'white', fontSize: '14px' }}
                                                onClick={() => handleClosePending(position.positionId)}
                                            />
                                        )}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
                <TableContainer component={Paper} style={{ backgroundColor: 'transparent', maxHeight: '300px', overflowY: 'auto' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Symbol</StyledTableCell>
                                <StyledTableCell>Type</StyledTableCell>
                                <StyledTableCell>Volume</StyledTableCell>
                                <StyledTableCell>Open Price</StyledTableCell>
                                <StyledTableCell>Close Price</StyledTableCell>
                                <StyledTableCell>T/P</StyledTableCell>
                                <StyledTableCell>S/L</StyledTableCell>
                                <StyledTableCell>Order</StyledTableCell>
                                <StyledTableCell>Open Time</StyledTableCell>
                                <StyledTableCell>Close Time</StyledTableCell>
                                <StyledTableCell>Commission</StyledTableCell>
                                <StyledTableCell>Reason</StyledTableCell>
                                <StyledTableCell>P/L, USD</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {closedPositions.map((position, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell>{position.symbol}</StyledTableCell>
                                    <StyledTableCell>{position.action}</StyledTableCell>
                                    <StyledTableCell>{position.lot}</StyledTableCell>
                                    <StyledTableCell>{Number(position.entryPrice).toFixed(2)}</StyledTableCell>
                                    <StyledTableCell>{Number(position.closingPrice).toFixed(2)}</StyledTableCell>
                                    <StyledTableCell>{position.takeProfit}</StyledTableCell>
                                    <StyledTableCell>{position.stopLoss}</StyledTableCell>
                                    <StyledTableCell>{position.positionId}</StyledTableCell>
                                    <StyledTableCell>{formatTimestamp(position.timestamp)}</StyledTableCell>
                                    <StyledTableCell>{formatTimestamp(position.closingTime)}</StyledTableCell>
                                    <StyledTableCell>{position.lot * 5}</StyledTableCell>
                                    <StyledTableCell>{position.comment}</StyledTableCell>
                                    <StyledTableCell style={{ color: position.profit >= 0 ? '#0376bc' : '#d84027' }}>
                                        {formatToMoney(position.profit)}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </TabPanel>
            {open ? (
                <Alert severity={severity} style={{ zIndex: 9999, marginTop: 10 }}>
                    {message}
                </Alert>
            ) : ""}
        </Box>
    );
};

export default Positions;
