import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, Avatar, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GridViewIcon from '@mui/icons-material/GridView';
import PublicIcon from '@mui/icons-material/Public';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Localbase from 'localbase'
import { Input } from '@mui/material';
import axios from 'axios'
import { formatToMoney } from '../utils/utils'
const AppBarContainer = styled(AppBar)({
    backgroundColor: '#2f3e46',
    zIndex: 1300,
});


const Logo = styled('img')({
    height: '40px',
    marginRight: '16px',
});

const LogoText = styled(Typography)({
    flexGrow: 1,
    color: '#ffd700',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
});

const NavItems = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
});

const DemoBadge = styled(Box)({
    color: '#00ff00',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
});

const Navbar = ({ onLoginStatus, Update }) => {
    const [profileAnchorEl, setProfileAnchorEl] = useState(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [AccountDetails, setAccountDetails] = useState(null)
    const [CurrentAccount, setCurrentAccount] = useState("")
    const [loginState, setLoginState] = useState("")

    let url;
    let db = new Localbase('MultiverseFunding')
    if (process.env.NODE_ENV === 'production') {
        url = "https://multitradefx-58ae1ffb4e56.herokuapp.com/api";
    } else {
        // URL = "http://localhost:3000/api";
        url = "https://multitradefx-58ae1ffb4e56.herokuapp.com/api";
    }
    const handleProfileClick = (event) => {
        setProfileAnchorEl(event.currentTarget);
    };

    const handleMenuClick = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setProfileAnchorEl(null);
        setMenuAnchorEl(null);
    };

    const handleSignOut = () => {
        console.log('Sign out clicked');
        handleClose();
    };

    const handleMenuItemClick = (url) => {
        window.open(url, '_blank');
        handleClose();
    };
    let dashboardUrl = "https://www.multiverse-funding.com/api"

    React.useEffect(() => {
        db.collection('user').get().then(users => {
            if (users.length === 0) {
                setLoginState("login")
            } else {
                setLoginState("logged in")
                axios.post(`${dashboardUrl}/find-user-by-email-for-id`, { email: users[0].email })
                    .then(res => {
                        setAccountDetails(res.data[0])
                    })
                    .catch(err => {
                        console.log(err)
                        setAccountDetails(users[0])
                    })
            }
        })
        db.collection('tradeAccount').get().then(users => {
            if (users.length !== 0) {
                setCurrentAccount(users[0].id)
            }
        })
    }, []);
    return (
        <AppBarContainer position="fixed">
            <Toolbar>
                <Logo src="https://astucefx.net/logo_no_bg.png" alt="Logo" />
                <LogoText variant="h6">MultiTrader</LogoText>
                <NavItems>
                    <FormControl variant="standard">
                        {AccountDetails !== null ?
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={CurrentAccount}
                                onChange={(e) => {
                                    db.collection('tradeAccount').doc({ id: CurrentAccount }).update({
                                        id: e.target.value
                                    }).then(e => {
                                        window.location.reload();
                                    }).catch(err => { alert("failed to logout") })
                                    setCurrentAccount(e.target.value)
                                    onLoginStatus(1)
                                }}
                                label="Select Currency Pair"
                                input={<Input disableUnderline />}
                            >
                                {AccountDetails.accountIDs.map((id, index) => (
                                    <MenuItem key={index} value={id}>{id}</MenuItem>
                                ))}
                            </Select>
                            : ""}

                    </FormControl>
                    <DemoBadge>
                        Balance <span>{Update ? formatToMoney(Update.CurrentBalance) : 0.00} USD</span>
                    </DemoBadge>
                    <DemoBadge>
                        Equity <span>{Update ? formatToMoney(Update.equity) : 0.00} USD</span>
                    </DemoBadge>
                    <IconButton color="inherit" onClick={handleMenuClick}>
                        <GridViewIcon />
                    </IconButton>
                    <Menu
                        anchorEl={menuAnchorEl}
                        open={Boolean(menuAnchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => handleMenuItemClick('https://www.multiverse-funding.com/')}>
                            <PublicIcon style={{ marginRight: 8 }} />
                            Website
                        </MenuItem>
                        <MenuItem onClick={() => handleMenuItemClick('https://www.multiverse-funding.com/Dashboard')}>
                            <DashboardIcon style={{ marginRight: 8 }} />
                            Dashboard
                        </MenuItem>
                    </Menu>
                    <IconButton color="inherit" onClick={handleProfileClick}>
                        <AccountCircleIcon />
                    </IconButton>
                    <Menu
                        anchorEl={profileAnchorEl}
                        open={Boolean(profileAnchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
                    </Menu>
                </NavItems>
            </Toolbar>
        </AppBarContainer>
    );
};

export default Navbar;
