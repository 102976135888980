import React, { useState } from 'react';
import { Box, Button, IconButton, TextField, Typography, Paper, Divider } from '@mui/material';
import { styled } from '@mui/system';
import PromotionalCard from './PromotionalCard'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios'
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import { Alert } from '@mui/material';

const SidebarContainer = styled(Paper)({
  position: 'fixed',
  top: '64px',
  right: 0,
  width: '260px',
  height: 'calc(100% - 64px)',
  backgroundColor: '#31404c',
  color: 'white',
  padding: '16px',
  overflowY: 'auto',
  zIndex: 1200,
});

const PriceBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '8px',
});

const PriceButton = styled(Box)({
  padding: '5px',
  flexGrow: 1,
  margin: '0 4px',
  textAlign: 'center',
  cursor: 'pointer',
  borderRadius: '4px',
});

const Slider = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '8px 0',
});

const OrderOptions = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '5px 0',
});

const ControlButton = styled(Button)({
  width: '100%',
  marginTop: '16px',
});

const TradingControls = ({ CurrentPair, Update, OHLC }) => {
  const getCurrencyPair = (data, pair) => {
    return data[pair] || {
      "bid": 0,
      "ask": 0,
      "mid": 0,
      "ts": 0
    };
  };
  const [currency, setCurrent] = useState({
    "bid": 0,
    "ask": 0,
    "mid": 0,
    "ts": 0
  })

  React.useEffect(() => {
    setCurrent(getCurrencyPair(OHLC, CurrentPair))
  }, [CurrentPair, OHLC]);

  const [amount, setAmount] = useState(0.01);
  const [action, setAction] = useState('');

  const handleIncrease = () => {
    setAmount(amount + 0.01);
  };

  const handleDecrease = () => {
    if (amount > 0.01) {
      setAmount(amount - 0.01);
    }
  };

  const [marketentry, setMarketentry] = React.useState('Market Execution');
  const marketEntry = (event) => {
    setMarketentry(event.target.value);
  };

  /*--------------SL AND HANDLER--------------- */
  const [slPrice, setSlPrice] = useState();

  /*----------------TP AND HANDLER----------------- */
  const [tpPrice, setTpPrice] = useState();
  const [entryPrice, setentryPrice] = useState()


  /*----------NOTIFIES IF POSITION OPENED---------- */
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('info');

  const handleClick = (msg, sev) => {
    setMessage(msg);
    setSeverity(sev);
    setOpen(true);
  };

  React.useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        setOpen(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [open]);
  const [loading, setLoading] = useState(false)

  let URL;
  if (process.env.NODE_ENV === 'production') {
    URL = "https://multitradefx-58ae1ffb4e56.herokuapp.com";
  } else {
    // URL = "http://localhost:3000";
    URL = "https://multitradefx-58ae1ffb4e56.herokuapp.com";
  }

  /*--------------------------------------------------SENDING EXECUTION--------------------------------------------------- */
  const sendOrder = async ({ accountId, symbol, lot, stopLoss, takeProfit, action, price }) => {
    const baseUrl = `${URL}/api/OrderSend`;
    try {
      if (!loading && accountId !== "" && symbol !== "") {
        setLoading(true)
        const response = await axios.post(baseUrl, { accountId, symbol, lot, stopLoss, takeProfit, action, price: price });
        if (response.data.success === true) {
          setLoading(false)
          handleClick('Position Opened successfully!', 'success')
        }
      }
    } catch (error) {
      console.error('Error sending order:', error);
      setLoading(false)
      handleClick('Error opening position!', 'error')
    }
  };
  /*----------------------------------------------------END---------------------------------------------------------------- */

  return (
    <SidebarContainer>
      <PriceBox>
        <PriceButton
          onClick={() => setAction('Sell')}
          style={{
            border: action === 'Sell' ? '2px solid #d84027' : '1px solid #333',
            backgroundColor: action === 'Sell' ? '#d84027' : 'transparent',
            color: action === 'Sell' ? 'white' : '#d84027',
          }}
        >
          <Typography variant="body2">Sell</Typography>
          <Typography variant="p">{currency.bid}</Typography>
        </PriceButton>
        <PriceButton
          onClick={() => setAction('Buy')}
          style={{
            border: action === 'Buy' ? '2px solid #0376bc' : '1px solid #333',
            backgroundColor: action === 'Buy' ? '#0376bc' : 'transparent',
            color: action === 'Buy' ? 'white' : '#0376bc',
          }}
        >
          <Typography variant="body2">Buy</Typography>
          <Typography variant="p">{currency.ask}</Typography>
        </PriceButton>
      </PriceBox>

      <TextField
        value={amount}
        variant="outlined"
        size="small"
        InputProps={{
          style: { color: 'white', borderColor: 'white' },
        }}
        style={{ margin: '5px 0', width: '100%' }}
        onChange={(e) => { setAmount(e.target.value) }}
      />
      <Box display="flex" justifyContent="space-between">
        <Button onClick={handleDecrease} variant="outlined" style={{ color: 'white', borderColor: 'white' }}>
          -
        </Button>
        <Button onClick={handleIncrease} variant="outlined" style={{ color: 'white', borderColor: 'white' }}>
          +
        </Button>
      </Box>
      <OrderOptions>
        <FormControl variant="standard" sx={{ minWidth: "100%" }}>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={marketentry}
            onChange={marketEntry}
            sx={{ color: "#fff" }}
          >
            <MenuItem defaultValue={true} value={"Market Execution"}>Market Execution</MenuItem>
            <MenuItem value={"Limit"}>Limit Order</MenuItem>
            <MenuItem value={"Stop"}>Stop Order</MenuItem>
          </Select>
        </FormControl>
      </OrderOptions>

      {marketentry !== 'Market Execution' ? <OrderOptions>
        <TextField
          variant="outlined"
          size="small"
          InputProps={{
            style: { color: 'white', borderColor: 'white' },
          }}
          style={{ margin: '5px 0', width: '100%' }}
          value={entryPrice}
          onChange={(event) => { setentryPrice(event.target.value); }}
          label='Entry Price'
        />
      </OrderOptions> : ""}
      <OrderOptions>
        <TextField
          variant="outlined"
          size="small"
          InputProps={{
            style: { color: 'white', borderColor: 'white' },
          }}
          style={{ margin: '5px 0', width: '100%' }}
          value={slPrice}
          onChange={(event) => { setSlPrice(event.target.value); }}
          label='Stop Loss'
        />
      </OrderOptions>
      <OrderOptions>
        <TextField
          variant="outlined"
          size="small"
          InputProps={{
            style: { color: 'white', borderColor: 'white' },
          }}
          style={{ margin: '5px 0', width: '100%' }}
          value={tpPrice}
          onChange={(event) => { setTpPrice(event.target.value) }}
          label='Take Profit'
        />

      </OrderOptions>

      {action !== '' ? <ControlButton
        variant="contained"
        style={{
          backgroundColor: action === 'Buy' ? '#0376bc' : '#d84027', fontSize: "12px"
        }}
        onClick={() => {
          if (marketentry == 'Market Execution') {
            sendOrder({ accountId: Update.accountId, symbol: CurrentPair, lot: amount, stopLoss: slPrice, takeProfit: tpPrice, action: action, entryPrice: 0 })
          } else {
            sendOrder({ accountId: Update.accountId, symbol: CurrentPair, lot:amount, stopLoss: slPrice, takeProfit: tpPrice, action: `${action} ${marketentry}`, price: entryPrice })
          }
        }}
      >
        {marketentry === 'Market Execution' ? `Confirm ${action}` : `Confirm  ${action} ${marketentry}`} {amount} lots
      </ControlButton> : ""}

      {action !== '' ? <ControlButton onClick={() => { setAction('') }} variant="outlined" style={{ color: 'white', borderColor: '#ffd700', marginTop: '8px', fontSize: "12px" }}>
        Cancel
      </ControlButton> : ""}

      {/* <PromotionalCard/> */}
      {open ? (
        <Alert severity={severity} style={{ zIndex: 9999, marginTop: 10 }}>
          {message}
        </Alert>
      ) : ""}
    </SidebarContainer>
  );
};

export default TradingControls;
