import React, { useEffect, useState } from 'react';
import './Trade.css'
import DeleteIcon from '@mui/icons-material/Delete';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import AddIcon from '@mui/icons-material/Add';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import Localbase from 'localbase'
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import ListItemButton from '@mui/material/ListItemButton';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { Typography, Button, Box, Input, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Divider, Slide, TextField } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import RemoveIcon from '@mui/icons-material/Remove';
import soundErr from "../sounds/err.wav"
import soundSuccess from "../sounds/ok.wav"

/*------------------------------------------IMPORT------------------------------------------- */

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Trades = ({ CurrentPair, updatePair, sendDataToParent, ...props }) => {
  let URL;

  if (process.env.NODE_ENV === 'production') {
    URL = "https://multitradefx-58ae1ffb4e56.herokuapp.com";
  } else {
    // URL = "http://localhost:3000";
    URL = "https://multitradefx-58ae1ffb4e56.herokuapp.com";
  }
  const playSoundErr = () => {
    // Ensure the path to the .wav file is correct
    const sound = new Audio(soundErr);
    sound.play()
      .catch(error => console.error("Error playing the sound:", error));
  };
  const playSoundSuccess = () => {
    // Ensure the path to the .wav file is correct
    const sound = new Audio(soundSuccess);
    sound.play()
      .catch(error => console.error("Error playing the sound:", error));
  };
  // const URL='https://multitradefx-58ae1ffb4e56.herokuapp.com'
  // const URL='http://localhost:3000'
  /*---------------QUOTE--------------- */
  const [Quote, setQuote] = useState([]);
  /*----------------END---------------- */

  /*-------------------ACCOUNT DETAILS------------------ */
  const [AccountDetails, setAccountDetails] = useState("")
  /*----------------------END--------------------------- */

  /*-------------------NEW DB------------------- */
  let db = new Localbase('MultiverseFunding')
  /*-----------------------END------------------ */

  /*------------------------------------------------UPDATE QUOTES VALUE--------------------------------------------- */
  useEffect(() => {
    const updatedData = props.Quote;
    const processedQuotes = updatedData && typeof updatedData === 'object' ? Object.entries(updatedData)
      .map(([symbol, details]) => ({
        Symbol: symbol,
        ...details // Spread the rest of the details (bid, ask, mid)
      }))
      .filter(quote => quote.bid !== 0 && quote.ask !== 0 && quote.mid !== 0) : []; // Filter out quotes with all zeroes
    setQuote(processedQuotes); // Update the quotes state
  }, [props.Quote]);
  /*-------------------------------------------------END------------------------------------------------------------ */

  /*--------------GET ACCOUNT ID && STORE------------- */
  const [accountId, setAccountId] = useState("")
  useEffect(() => {
    db.collection('tradeAccount').get().then(users => {
      if (users.length !== 0) {
        setAccountId(users[0].id)
      }
    })
  }, []);
  /*-----------------------END------------------------ */


  /*-----------------SELECTED PAIRS-------------------- */
  const [selectedPair, setSelectedPair] = useState(null);//EURUSD/GBPUSD

  const toggleDeleteMenu = (pair) => {
    setSelectedPair(pair === selectedPair ? null : pair);
  };
  /*-------------------------END------------------------ */

  /*-------------------------ENTRY TYPE AND HANDLER---------------------- */
  const [marketentry, setMarketentry] = React.useState('Market Execution');
  const marketEntry = (event) => {
    setMarketentry(event.target.value);
  };
  /*----------------------------------END------------------------------- */

  /*--------------LOTSIZE--------------- */
  const [lot, setLot] = useState(0.01)
  /*----------------END----------------- */

  /*----------------ENTRY PRICE---------------- */
  const [Entryprice, setEntryprice] = useState()
  /*--------------------END-------------------- */


  /*--------------SL AND HANDLER--------------- */
  const [slPrice, setSlPrice] = useState();
  /*---------------------END---------------------- */

  /*----------------TP AND HANDLER----------------- */
  const [tpPrice, setTpPrice] = useState();
  /*---------------------END---------------------- */

  /*----------NOTIFIES IF POSITION OPENED---------- */
  const [openSnack, setOpenSnack] = React.useState(false);

  const handleClickSnack = () => {
    setOpenSnack(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnack}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  /*-----------------------END------------------- */

  const [tradingPair, setTradingPair] = useState(null);
  const [Entrypair, setEntrypair] = useState(null);

  useEffect(() => {
    if (tradingPair !== null) {
      const foundQuote = Quote.find(quote => quote.Symbol === tradingPair.Symbol);
      setEntrypair(foundQuote);
    }

  }, [tradingPair, Quote]);

  const [loading, setLoading] = useState(false)
  /*------------------------------------------------Open  position------------------------------------------------------- */
  const sendOrder = async ({ accountId, symbol, lot, stopLoss, takeProfit, action, price }) => {
    const baseUrl = `${URL}/api/OrderSend`;

    try {
      if (!loading) {
        setLoading(true)
        const response = await axios.post(baseUrl, { accountId, symbol, lot, stopLoss, takeProfit, action, price: price });
        if (response.data.success === true) {
          handleClickSnack();
          playSoundSuccess()
          setLoading(false)
        }
      }

    } catch (error) {
      console.error('Error sending order:', error);
      playSoundErr()
      setLoading(false)
    }
  };


  /*------------------------------------Search-------------------------------- */

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    db.collection('CurrentAccount').get().then(users => {
      if (users.length !== 0) {
        setAccountDetails(users[0].id)
      }
    })
  }, []);
  const [addingPair, setAddingpair] = useState("")

  return (

    <div className="main-trade-container">
      <div>
        <React.Fragment>
          <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>

                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">

                  Quotes
                </Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <List>
              {Quote.length > 0 ? Quote.map((quote, index) => (
                <ListItemButton key={index}>
                  <IconButton aria-label="add">
                    {addingPair !== quote.Symbol ? <ControlPointIcon style={{ color: "#1976d2" }} /> : <CircularProgress size={20} />}
                  </IconButton>
                  <ListItemText primary={quote.Symbol} />
                </ListItemButton>
              )) : <p>No valid quotes to display.</p>}
            </List>
          </Dialog>
        </React.Fragment>
      </div>

      {tradingPair === null ?
        <div className="watchlist">

          <div>
            <Typography className='inputsearch' align="center" gutterBottom>
              <div style={{ display: "flex", justifyContent: "space-between", margin: "0 10px" }}>
                <p style={{ padding: 0, margin: 0, display: "flex" }} onClick={handleClickOpen}><ExpandMoreIcon style={{ color: "blue" }} /><span>Quotes</span></p>
                <AddIcon onClick={() => { handleClickOpen() }} style={{ width: "50px", color: "blue" }} />
              </div>
            </Typography>
            <List style={{ maxHeight: '80vh', overflowY: 'auto', width: '100%' }}>
              {Quote.map((pair, index) => {
                // If pair.Ask and pair.Bid are 0, don't render this item
                if (pair.Ask === 0 && pair.Bid === 0) {
                  return null;
                }

                return (
                  <div key={index}>
                    <ListItem button onClick={() => toggleDeleteMenu && toggleDeleteMenu(pair.Symbol)}>
                      <ListItemText style={{ textAlign: "left", width: "100px" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          {/* <span style={{ fontSize: "12px" }}><b>{formatDateTime ? formatDateTime(pair.Time) : pair.Time}</b></span> */}
                          <span><b>{pair.Symbol}</b></span>
                          <span style={{ fontSize: "12px" }}>spread: {calculateSpread(pair.bid, pair.ask)}</span>
                        </div>
                      </ListItemText>
                      <ListItemText style={{ textAlign: "left", width: "100px" }}>
                        <span style={{ color: "blue" }}><b>{formatPrice ? formatPrice(pair.ask) : pair.ask}</b></span>
                      </ListItemText>
                      <ListItemText style={{ textAlign: "right" }}>
                        <span style={{ color: "blue" }}><b>{formatPrice ? formatPrice(pair.bid) : pair.bid}</b></span>
                      </ListItemText>
                      {selectedPair && (
                        <Slide direction="left" in={selectedPair === pair.Symbol} mountOnEnter unmountOnExit>
                          <ListItemSecondaryAction className='action-slides'>
                            <button onClick={() => setTradingPair({ Symbol: pair.Symbol })} className='Slide-To-add'><AddIcon /></button>
                            <button className='Slide-To-delete'><DeleteIcon onClick={() => {
                              alert("coming soon")
                            }} /></button>
                            <button onClick={() => {
                              updatePair(pair.Symbol)
                              sendDataToParent(1)
                            }} className='Slide-To-Trade'><InsertChartIcon /></button>
                          </ListItemSecondaryAction>
                        </Slide>
                      )}
                    </ListItem>
                    {index < Quote.length - 1 && <Divider />}
                  </div>
                );
              })}
            </List>
          </div>
        </div>
        :

        <div className="trading">
          <div className='tradingBox'>
            <div className='header-tradingBox'>
              <div className='pair-trade-header'>
                <ArrowBackIosIcon onClick={() => { setTradingPair(null) }} />
                <div className='pair-trade-header-main'>
                  <FormControl variant="standard">
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={tradingPair.Symbol}
                      onChange={(event) => {
                        setTradingPair({ Symbol: event.target.value });
                      }}
                      label="Select Currency Pair"
                      input={<Input disableUnderline />}
                    >
                      {Quote.filter(pair => pair.ask !== 0).map((pair, index) => (
                        <MenuItem key={index} value={pair.Symbol}>{pair.Symbol}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <Divider />
              <FormControl variant="standard" sx={{ m: 1, minWidth: "100%" }}>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={marketentry}
                  onChange={marketEntry}
                  label="Age"
                >
                  <MenuItem defaultValue={true} value={"Market Execution"}>Market Execution</MenuItem>
                  <MenuItem value={"Buy Limit"}>Buy Limit</MenuItem>
                  <MenuItem value={"Sell Limit"}>Sell Limit</MenuItem>
                  <MenuItem value={"Buy Stop"}>Buy Stop</MenuItem>
                  <MenuItem value={"Sell Stop"}>Sell Stop</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className='Market-Execution'>
              <div className='lot-size-part'>
                <Button onClick={() => {
                  setLot(e => {
                    let a = e - 1;
                    if (a > 0.1) {
                      return a
                    } else {
                      return e
                    }
                  })
                }}>-1</Button>
                <Button onClick={() => {
                  setLot(e => {
                    let a = e - 0.5;
                    if (a > 0.1) {
                      return a
                    } else {
                      return e
                    }
                  })
                }}>-0.5</Button>
                <TextField
                  type="number"
                  value={lot}
                  onChange={(e) => {
                    setLot(e.target.value);
                  }}
                  onBlur={(e) => {
                    if (e.target.value < 0.01) {
                      setLot(0.01);
                    }
                  }}
                  placeholder="Lot"
                  variant="standard" // Use the standard variant
                  InputProps={{
                    disableUnderline: true // Disable the underline to make it borderless
                  }}
                />
                <Button onClick={() => { setLot(lot + 1); }}>+1</Button>
                <Button onClick={() => { setLot(lot + 5); }}>+5</Button>
              </div>
              <div className='sl-tp-upTodate'>
                {marketentry !== "Market Execution" ?
                  <div className='price'>
                    <p>Price</p>
                    <div>
                      <RemoveIcon />
                      <input
                        placeholder='not set'
                        value={Entryprice}
                        onChange={(e) => { setEntryprice(e.target.value) }}
                      />
                      <AddIcon />
                    </div>

                  </div>
                  : ""}


                <div className='sl'>
                  <p>Stop Loss</p>
                  <div>
                    <RemoveIcon />
                    <input
                      value={slPrice}
                      onChange={(event) => { setSlPrice(event.target.value); }}
                      placeholder='not set'
                    />
                    <AddIcon />
                  </div>

                </div>
                <div className='tp'>
                  <p>Take Profit</p>
                  <div>
                    <RemoveIcon />
                    <input
                      value={tpPrice}
                      onChange={(event) => { setTpPrice(event.target.value) }}
                      placeholder='not set'
                    />
                    <AddIcon />
                  </div>

                </div>
              </div>
              <div className='price-btn'>
                <span>{Entrypair !== null ? formatPrice(Entrypair.ask) : ""}</span>
                <span>{Entrypair !== null ? formatPrice(Entrypair.bid) : ""}</span>
              </div>
              {marketentry === "Market Execution" ?
                <div className='btn-entry'>
                  <Button disabled={loading} onClick={() => {
                    sendOrder({ accountId: accountId, symbol: tradingPair.Symbol, lot, stopLoss: slPrice, takeProfit: tpPrice, action: "Sell", entryPrice: 0 })
                  }} variant='contained' color='error'>
                    <span style={{ display: "flex" }}>{loading === true ? <CircularProgress size={20} /> : <span>Sell by Market</span>}   <ArrowDropDownIcon /></span>

                  </Button>
                  <Button disabled={loading} onClick={() => {
                    sendOrder({ accountId: accountId, symbol: tradingPair.Symbol, lot, stopLoss: slPrice, takeProfit: tpPrice, action: "Buy", entryPrice: 0 })
                  }} variant='contained'>
                    <span style={{ display: "flex", }}> {loading === true ? <CircularProgress size={20} /> : <span>Buy by Market</span>}   <ArrowDropUpIcon /></span>
                  </Button>


                </div>
                :
                <div className='btn-entry-pending'>
                  <Button onClick={(e) => {
                    e.preventDefault()
                    console.log(marketentry)
                    switch (marketentry) {
                      case "Sell Stop":
                        sendOrder({ accountId: accountId, symbol: tradingPair.Symbol, lot, stopLoss: slPrice, takeProfit: tpPrice, action: "Sell Stop", price: Entryprice })
                        break;
                      case "Buy Stop":
                        sendOrder({ accountId: accountId, symbol: tradingPair.Symbol, lot, stopLoss: slPrice, takeProfit: tpPrice, action: "Buy Stop", price: Entryprice })
                        break;
                      case "Sell Limit":
                        sendOrder({ accountId: accountId, symbol: tradingPair.Symbol, lot, stopLoss: slPrice, takeProfit: tpPrice, action: "Sell Limit", price: Entryprice })
                        break;
                      case "Buy Limit":
                        sendOrder({ accountId: accountId, symbol: tradingPair.Symbol, lot, stopLoss: slPrice, takeProfit: tpPrice, action: "Buy Limit", price: Entryprice })
                        break;
                      default:
                        break;
                    }
                  }} variant='contained' >
                    {loading === true ? <CircularProgress size={20} /> : <span style={{ display: "flex" }}> <span>Place Order</span> </span>}


                  </Button>
                </div>
              }
            </div>




          </div>
          <div>
            <Snackbar
              open={openSnack}
              autoHideDuration={500}
              onClose={handleCloseSnack}
              message="Position opened successFully"
              action={action}
            />

          </div>

        </div>}
    </div>
  );
};


/*-----------------------------------------PRICE FORMAT ON BUTTON------------------------------ */
function formatPrice(price, font) {
  let p = price
  const priceString = p.toString();
  const decimalPos = priceString.indexOf('.');
  const numDecimals = decimalPos === -1 ? 0 : priceString.length - decimalPos - 1;
  const formattedPrice = numDecimals > 5 ? +p.toFixed(5) : p;
  const [whole, decimals] = formattedPrice.toString().split('.');
  const decimalPlaces = decimals ? decimals.length : 0;
  const fontSize = font ? font : 0.8;

  if (decimalPlaces === 2) {
    return (
      <span>
        <span style={{ fontSize: `${fontSize}rem` }}>{whole}</span>.<span style={{ fontSize: `${fontSize * 1.5}rem` }}>{decimals}</span>
      </span>
    );
  } else if (decimalPlaces >= 3) {
    return (
      <span>
        <span style={{ fontSize: `${fontSize}rem` }}>{whole}</span>.<span style={{ fontSize: `${fontSize * 1.5}rem` }}>{decimals.slice(0, -1)}</span>
        <sup style={{ fontSize: '1rem', verticalAlign: 'super' }}>{decimals.slice(-1)}</sup>
      </span>
    );
  } else {
    return price;
  }
}

function calculateSpread(bid, ask) {
  // Determine the number of decimal places used in the bid/ask prices
  const decimalPlaces = determineDecimalPlaces(bid, ask);

  // Calculate the spread in pips
  const spread = ask - bid;
  let spreadInPips = spread * Math.pow(10, decimalPlaces); 
  spreadInPips=spreadInPips.toFixed(0)
  return spreadInPips;
}
function determineDecimalPlaces(bid, ask) {
  // Convert the numbers to strings
  const bidStr = bid.toString();
  const askStr = ask.toString();

  // Extract the number of decimal places
  const bidDecimals = bidStr.includes('.') ? bidStr.split('.')[1].length : 0;
  const askDecimals = askStr.includes('.') ? askStr.split('.')[1].length : 0;

  // Use the maximum number of decimal places found in either the bid or the ask
  return Math.max(bidDecimals, askDecimals);
}

/*-----------------------------------------------END---------------------------------------------- */


