import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import Positions from './Positions';
import Localbase from 'localbase';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css'; // Ensure this is imported

const BottomBarContainer = styled(Box)({
    backgroundColor: '#29323a',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
    boxSizing: 'border-box',
    flexDirection: 'column',
    overflow: 'auto', // Allow content to scroll if needed
    height: '100%', // Ensure it takes full height of the ResizableBox container
});

const BottomBar = ({ Update }) => {
    
    let url;
    if (process.env.NODE_ENV === 'production') {
        url = "https://multitradefx-58ae1ffb4e56.herokuapp.com";
    } else {
        url = "https://multitradefx-58ae1ffb4e56.herokuapp.com";
    }

    let db = new Localbase('MultiverseFunding');

    const [openPositions, setOpenPositions] = React.useState([]);
    const [pendingPositions, setPendingPositions] = React.useState([]);

    React.useEffect(() => {
        if (Update.openPositions) {
            setOpenPositions(Update.openPositions);
            setPendingPositions(Update.pendingOrders);
        }
    }, [Update]);



    return (
        <ResizableBox
            width="100%"
            height={window.innerHeight * 0.3} // Initial height set to 30% of viewport height
            minConstraints={[Infinity, window.innerHeight * 0.1]} // Minimum height set to 10% of viewport height
            maxConstraints={[Infinity, window.innerHeight * 0.9]} // Maximum height set to 90% of viewport height
            axis="y"
            resizeHandles={['n']} // Enable resizing from the north (top) edge
            style={{ 
                position: 'fixed', 
                bottom: 0, 
                left: '260px', 
                right: '260px', 
                zIndex: 1200,
                backgroundColor: '#29323a',
            }}
        >
            <BottomBarContainer>
                <Positions
                    openPositions={openPositions}
                    pendingPositions={pendingPositions}
                    accountId={Update.accountId}
                />
            </BottomBarContainer>
        </ResizableBox>
    );
};

export default BottomBar;
