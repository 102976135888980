import React, { useState } from 'react';
import {
  Drawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Box,
  Paper,
  Divider  // Include this import for Divider
} from '@mui/material';
import { styled } from '@mui/system';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const drawerWidth = 260;

const DrawerContainer = styled(Drawer)({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    backgroundColor: '#29323a', // dark background color
    color: '#ffffff', // white text color
    paddingTop: '64px', // Adjust based on navbar height
  },
});

const SearchContainer = styled(Box)({
  padding: '16px',
});

const StyledTableCell = styled(TableCell)({
  color: 'white',
  padding: '5px 0px', // Remove padding
});

const StyledTableRow = styled(TableRow)({
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: 'transparent', // Transparent background
    },
    '&:nth-of-type(even)': {
      backgroundColor: 'transparent', // Transparent background
    },
  });

const Sidebar = ({ assets, onAssetClick }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredAssets = assets.filter(asset =>
    asset.Symbol.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <DrawerContainer variant="permanent">
      <SearchContainer>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            style: { color: 'white', borderColor: 'white' },
          }}
        />
      </SearchContainer>
      <Divider style={{ backgroundColor: 'white' }} />
      <TableContainer component={Paper} style={{ backgroundColor: 'inherit' }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Symbol</StyledTableCell>
              <StyledTableCell>Bid</StyledTableCell>
              <StyledTableCell>Ask</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAssets.map((asset) => (
              <StyledTableRow key={asset.Symbol} onClick={() => onAssetClick(asset)}>
                <StyledTableCell>{asset.Symbol}</StyledTableCell>
                <StyledTableCell>
                  {asset.bidChange > 0 ? <ArrowDropUpIcon style={{ color: 'green' }} /> : <ArrowDropDownIcon style={{ color: 'red' }} />}
                  {asset.bid}
                </StyledTableCell>
                <StyledTableCell>
                  {asset.askChange > 0 ? <ArrowDropUpIcon style={{ color: 'green' }} /> : <ArrowDropDownIcon style={{ color: 'red' }} />}
                  {asset.ask}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </DrawerContainer>
  );
};

export default Sidebar;
