import React, { useEffect, useRef, useState } from 'react';
import './Charts.css';
import { createChart, CrosshairMode } from "lightweight-charts";
import { Snackbar, Input, Button, TextField, IconButton } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios'
import soundErr from "../sounds/err.wav"
import soundSuccess from "../sounds/ok.wav"
import SettingsIcon from '@mui/icons-material/Settings';
import { Typography, List, ListItem, ListItemText, Grid, Switch, Slide } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import ListItemButton from '@mui/material/ListItemButton';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import { SketchPicker } from 'react-color';
import Localbase from 'localbase';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const Charts = ({ OHLC, Update, CurrentPair }) => {
    let db = new Localbase('MultiverseChartSetting');
    const [settings, setSettings] = React.useState({
        tradeOrders: true,
        tradePositions: true,
        slTpLevels: true,
        tradeHistory: false,
        grid: true
    });
    /*-------------------------------SOUND---------------------------------- */
    const playSoundErr = () => {
        const sound = new Audio(soundErr);
        sound.play()
            .catch(error => console.error("Error playing the sound:", error));
    };
    const playSoundSuccess = () => {
        const sound = new Audio(soundSuccess);
        sound.play()
            .catch(error => console.error("Error playing the sound:", error));
    };
    /*-------------------------------END---------------------------------- */
    const [loading, setLoading] = useState(false)
    /*-------------------------------URL---------------------------------- */
    let URL;
    if (process.env.NODE_ENV === 'production') {
        URL = "https://multitradefx-58ae1ffb4e56.herokuapp.com";
    } else {
        URL = "http://localhost:3000";
        // URL = "https://multitradefx-58ae1ffb4e56.herokuapp.com";
    }

    /*-------------------------------STATES---------------------------------- */
    const chartContainerRef = useRef(null);
    const chart = useRef(null);
    const candleSeriesRef = useRef(null);
    const [currentChart, setCurrentChart] = useState(CurrentPair)
    const [currentTimeframe, setCurrentTimeframe] = useState("H1")
    const [EntryLevel, setEntryLevel] = useState([])
    const [EntryPending, setPending] = useState([])
    const [accountId, setAccountId] = useState(null)
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    /*--------------LOTSIZE--------------- */
    const [lot, setLot] = useState(0.01)
    /*----------------END----------------- */
    const [colorSettings, setColorSettings] = useState({
        foreground: { color: '#253248', isOpen: false },
        text: { color: 'black', isOpen: false },
        grid: { color: '#CCCCCC', isOpen: false },
        bullBorderCandle: { color: '#4bffb5', isOpen: false },
        bullBodyCandle: { color: '#4bffb5', isOpen: false },
        bearBorderCandle: { color: '#ff4976', isOpen: false },
        bearBodyCandle: { color: '#ff4976', isOpen: false },
        wickUp: { color: '#838ca1', isOpen: false },
        wickDown: { color: '#838ca1', isOpen: false },
        timeScale: { color: '#485c7b', isOpen: false }
    });
    // Load settings from Localbase when component mounts
    useEffect(() => {
        async function fetchColorSettings() {
            try {
                await db.collection('settings').doc({ id: 1 }).update({
                    setting: colorSettings
                })
            } catch (error) {
                console.error("Failed to fetch color settings:", error);
            }
        }
        fetchColorSettings();
    }, [colorSettings]);

    useEffect(() => {
        async function fetchColorSettings() {
            try {
                const data = await db.collection('settings').get();
                if (data.length !== 0) {
                    console.log(data)
                    setColorSettings(data.setting);
                } else {
                    await db.collection('settings').add({ id: 1, setting: colorSettings })
                }
            } catch (error) {
                await db.collection('settings').add({ id: 1, setting: colorSettings })
                console.error("Failed to fetch color settings:", error);
            }
        }
        fetchColorSettings();
    }, []);

    /*------------------------------------------------Open  position------------------------------------------------------- */
    const sendOrder = async ({ accountId, symbol, lot, stopLoss, takeProfit, action, entryPrice }) => {
        const baseUrl = `${URL}/api/OrderSend`;
        try {
            if (!loading) {
                setLoading(true)
                const response = await axios.post(baseUrl, { accountId, symbol, lot, stopLoss, takeProfit, action, price: entryPrice });
                console.log(response.data.message)
                if (response.data.success === true) {
                    playSoundSuccess()
                    setLoading(false)
                    setSnackbar({ open: true, message: 'Position opened successfully!', severity: 'success' });
                }
            }
        } catch (error) {
            setSnackbar({ open: true, message: 'Error opening order', severity: 'error' });
            console.error('Error sending order:', error);
            playSoundErr()
            setLoading(false)
        }
    };
    /*----------------------------------------------------------END------------------------------------------------------------- */



    /*----------ALL PAIRS WITH CURRENT OHLC----------------- */
    const [globalOHLC, setglobalOHLC] = useState({
        AUDNZD: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        CHDJPY: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        AUDCAD: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        AUDJPY: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        AUDCHF: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        XAUUSD: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        XAUEUR: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        EURUSD: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        EURNZD: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        GBPUSD: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        BTCUSD: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        DOGEUSD: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        ETHUSD: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        GBPJPY: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        USDJPY: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        EURCHF: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        EURGBP: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        AUDUSD: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        EURJPY: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        GBPAUD: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        GBPCAD: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        GBPCHF: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        NZDCAD: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        NZDCHF: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        NZDJPY: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        NZDUSD: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        USDCAD: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        USDCHF: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        XAGUSD: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        US30: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        NAS100: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        EURNOK: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        EURTRY: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        ADAUSD: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        DOGEUSD: { time: 0, open: 0, high: 0, low: 0, close: 0 },
        OIL: { time: 0, open: 0, high: 0, low: 0, close: 0 }
    })
    /*-------------------------END-------------------------*/


    /*------------------------------FUNCTION PAIRS TO OHLC------------------------------ */
    function convertToOHLC(data) {
        const { symbol, mid, ts } = data;
        // Copy the current state to avoid direct mutation
        const newOHLC = { ...globalOHLC };
        if (newOHLC[symbol].time !== 0) {
            const existingDate = newOHLC[symbol].time;
            let conversionTime = Number(ts);
            let date = new Date(conversionTime);
            date.setSeconds(0, 0);
            let roundedTimestamp = Math.floor(date.getTime() / 1000);
            const newDate = roundedTimestamp;
            if (existingDate !== newDate) {
                newOHLC[symbol] = {
                    time: newDate,
                    open: mid,
                    high: mid,
                    low: mid,
                    close: mid
                };
            } else {
                newOHLC[symbol] = {
                    ...newOHLC[symbol],
                    high: Math.max(newOHLC[symbol].high, mid),
                    low: Math.min(newOHLC[symbol].low, mid),
                    close: mid
                };
            }
        }
        setglobalOHLC(newOHLC);
    }
    /*--------------------------------END---------------------------------------- */

    /*----------------------------------CALL CONVERT PAIRS TO OHLC---------------------------------- */
    useEffect(() => {
        if (OHLC[currentChart] !== undefined) {
            let data = { symbol: currentChart, mid: OHLC[currentChart].mid, ts: OHLC[currentChart].ts }
            convertToOHLC(data)
        }
    }, [OHLC]);
    /*-----------------------------------------------END-------------------------------------------- */

    /*----------------------------------------------CREATE THE CHART-------------------------------------------- */
    useEffect(() => {
        if (chartContainerRef.current && !chart.current) {
            // Create the chart
            chart.current = createChart(chartContainerRef.current, {
                width: chartContainerRef.current.clientWidth,
                height: window.innerHeight * 0.8,
                layout: {
                    backgroundColor: "#253248",
                    textColor: "black"
                },
                crosshair: {
                    mode: CrosshairMode.Normal
                },
                priceScale: {
                    borderColor: "#485c7b"
                },
                timeScale: {
                    borderColor: "#485c7b",
                    timeVisible: true,
                    secondsVisible: false,
                },
            });
            candleSeriesRef.current = chart.current.addCandlestickSeries({
                upColor: "#4bffb5",
                downColor: "#ff4976",
                borderDownColor: "#ff4976",
                borderUpColor: "#4bffb5",
                wickDownColor: "#838ca1",
                wickUpColor: "#838ca1"
            });
            candleSeriesRef.current.applyOptions({
                priceFormat: {
                    type: 'price',
                    precision: 6,
                    minMove: 0.000001,
                },
            });
        }
    }, []);

    useEffect(() => {
        if (chart.current) {
            chart.current.applyOptions({
                grid: {
                    vertLines: {
                        color: settings.grid ? '#e0e0e0' : 'transparent', // Show/hide vertical grid lines
                    },
                    horzLines: {
                        color: settings.grid ? '#e0e0e0' : 'transparent', // Show/hide horizontal grid lines
                    },
                }
            });
        }
    }, [settings.grid]);

    useEffect(() => {
        if (chart.current && candleSeriesRef.current) {
            // Updating the general chart layout colors
            chart.current.applyOptions({
                layout: {
                    backgroundColor: colorSettings.foreground.color,
                    textColor: colorSettings.text.color, // Assuming you have this setting, or adjust accordingly
                },
                grid: {
                    vertLines: {
                        color: colorSettings.grid.color,
                    },
                    horzLines: {
                        color: colorSettings.grid.color,
                    },
                },
            });

            // Updating candle series specific colors
            candleSeriesRef.current.applyOptions({
                upColor: colorSettings.bullBodyCandle.color,
                downColor: colorSettings.bearBodyCandle.color,
                borderDownColor: colorSettings.bearBorderCandle.color,
                borderUpColor: colorSettings.bullBorderCandle.color,
                wickDownColor: colorSettings.wickDown.color,
                wickUpColor: colorSettings.wickUp.color,
            });
        }
    }, [colorSettings]);
    /*----------------------------------------------END-------------------------------------------- */



    useEffect(() => {
        let symbol;
            if(currentChart=="US30"){
                symbol="US30USD"
            }else if(currentChart=="NAS100"){
                symbol="NAS100USD"
            }else{
                symbol=currentChart
            }
        if (currentTimeframe == "D") {
            axios.post(`${URL}/api/fetch-time-series`, { currency: symbol, interval: "daily" })
                .then(res => {
                    if (res.data.quotes.length !== 0 && res) {
                        const transformedData = res.data.quotes.map(data => ({
                            close: data.close,
                            time: data.date,  // Rename date to time
                            high: data.high,
                            low: data.low,
                            open: data.open
                        }));
                        candleSeriesRef.current.setData(transformedData);
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        } else if (currentTimeframe == "H4") {
            axios.post(`${URL}/api/fetch-time-series`, { currency: symbol, interval: "hourly", period: 4 })
                .then(res => {
                    if (res.data.quotes.length !== 0 && res) {
                        const transformedData = res.data.quotes.map(data => ({
                            close: data.close,
                            time: Date.parse(data.date) / 1000,  // Rename date to time
                            high: data.high,
                            low: data.low,
                            open: data.open
                        }));
                        candleSeriesRef.current.setData(transformedData);
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        } else if (currentTimeframe == "H1") {
            axios.post(`${URL}/api/fetch-time-series`, { currency: symbol, interval: "hourly", period: 1 })
                .then(res => {
                    if (res.data.quotes.length !== 0 && res) {
                        const transformedData = res.data.quotes.map(data => ({
                            close: data.close,
                            time: Date.parse(data.date) / 1000,  // Rename date to time
                            high: data.high,
                            low: data.low,
                            open: data.open
                        }));
                        candleSeriesRef.current.setData(transformedData);
                        const newOHLC = { ...globalOHLC };
                        newOHLC[currentChart] = transformedData[transformedData.length - 1];
                        setglobalOHLC(newOHLC);
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
        else if (currentTimeframe == "M30") {
            axios.post(`${URL}/api/fetch-time-series`, { currency: symbol, interval: "minute", period: 30 })
                .then(res => {
                    if (res.data.quotes.length !== 0 && res) {
                        const transformedData = res.data.quotes.map(data => ({
                            close: data.close,
                            time: Date.parse(data.date) / 1000,  // Rename date to time
                            high: data.high,
                            low: data.low,
                            open: data.open
                        }));
                        candleSeriesRef.current.setData(transformedData);
                        const newOHLC = { ...globalOHLC };
                        newOHLC[currentChart] = transformedData[transformedData.length - 1];
                        setglobalOHLC(newOHLC);
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
        else if (currentTimeframe == "M15") {
            axios.post(`${URL}/api/fetch-time-series`, { currency: symbol, interval: "minute", period: 15 })
                .then(res => {
                    if (res.data.quotes.length !== 0 && res) {
                        const transformedData = res.data.quotes.map(data => ({
                            close: data.close,
                            time: Date.parse(data.date) / 1000,  // Rename date to time
                            high: data.high,
                            low: data.low,
                            open: data.open
                        }));
                        candleSeriesRef.current.setData(transformedData);
                        const newOHLC = { ...globalOHLC };
                        newOHLC[currentChart] = transformedData[transformedData.length - 1];
                        setglobalOHLC(newOHLC);
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
        else if (currentTimeframe == "M5") {
            axios.post(`${URL}/api/fetch-time-series`, { currency: symbol, interval: "minute", period: 5 })
                .then(res => {
                    if (res.data.quotes.length !== 0 && res) {
                        const transformedData = res.data.quotes.map(data => ({
                            close: data.close,
                            time: Date.parse(data.date) / 1000,  // Rename date to time
                            high: data.high,
                            low: data.low,
                            open: data.open
                        }));
                        candleSeriesRef.current.setData(transformedData);
                        const newOHLC = { ...globalOHLC };
                        newOHLC[currentChart] = transformedData[transformedData.length - 1];
                        setglobalOHLC(newOHLC);
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
        else if (currentTimeframe == "M1") {
            axios.post(`${URL}/api/fetch-time-series`, { currency: symbol, interval: "minute", period: 1 })
                .then(res => {
                    if (res.data.quotes.length !== 0 && res) {
                        const transformedData = res.data.quotes.map(data => ({
                            close: data.close,
                            time: Date.parse(data.date) / 1000,
                            high: data.high,
                            low: data.low,
                            open: data.open
                        }));
                        candleSeriesRef.current.setData(transformedData);
                        const newOHLC = { ...globalOHLC };
                        newOHLC[currentChart] = transformedData[transformedData.length - 1];
                        setglobalOHLC(newOHLC);
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [currentTimeframe, currentChart]);

    useEffect(() => {
        if (candleSeriesRef.current) {
            if (globalOHLC[currentChart].time !== 0) {
                candleSeriesRef.current.update(globalOHLC[currentChart]);
            }
        }
    }, [OHLC]);




    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            const { width, height } = entries[0].contentRect;
            chart.current.applyOptions({ width, height });
            setTimeout(() => {
                chart.current.timeScale().fitContent();
            }, 0);
        });

        if (chartContainerRef.current) {
            resizeObserver.observe(chartContainerRef.current);
        }
        return () => resizeObserver.disconnect();
    }, []);

    const [hasRun, setHasRun] = useState(false);
    useEffect(() => {
        let CurrentEntry = []
        let CurrentPending = []
        if (!hasRun) {
            if (Update.openPositions !== undefined && Update.openPositions.length !== 0) {
                Update.openPositions.map(i => {
                    if (currentChart === i.symbol) {
                        if (i.stopLoss != null) {
                            if (i.symbol == "BTCUSD") {
                                CurrentEntry.push({
                                    price: i.stopLoss,
                                    color: "#FF3232",
                                    lineWidth: 1,
                                    lineStyle: 3,
                                    axisLabelVisible: true,
                                    title: `SL ${CryptoProfit(i.stopLoss, i.entryPrice, i.lot)}$`
                                })
                            } else if (i.symbol == "XAUUSD" || i.symbol == "ETHUSD") {
                                CurrentEntry.push({
                                    price: i.stopLoss,
                                    color: "#FF3232",
                                    lineWidth: 1,
                                    lineStyle: 3,
                                    axisLabelVisible: true,
                                    title: `SL ${calculateGold(i.stopLoss, i.entryPrice, i.lot)}$`
                                })
                            } else if (i.symbol == "EURJPY" || i.symbol == "GBPJPY" || i.symbol == "USDJPY" || i.symbol == "NZDJPY" || i.symbol == "AUDJPY") {
                                CurrentEntry.push({
                                    price: i.stopLoss,
                                    color: "#FF3232",
                                    lineWidth: 1,
                                    lineStyle: 3,
                                    axisLabelVisible: true,
                                    title: `SL ${calculateJPY(i.stopLoss, i.entryPrice, i.lot)}$`
                                })
                            }
                            else if (i.symbol == "US30" || i.symbol == "NAS100") {
                                CurrentEntry.push({
                                    price: i.stopLoss,
                                    color: "#FF3232",
                                    lineWidth: 1,
                                    lineStyle: 3,
                                    axisLabelVisible: true,
                                    title: `SL ${calculateIndice(i.stopLoss, i.entryPrice, i.lot)}$`
                                })
                            }
                            else {
                                CurrentEntry.push({
                                    price: i.stopLoss,
                                    color: "#FF3232",
                                    lineWidth: 1,
                                    lineStyle: 3,
                                    axisLabelVisible: true,
                                    title: `SL ${calculateProfit(i.stopLoss, i.entryPrice, i.lot)}$`
                                })
                            }

                        }
                        if (i.takeProfit != null) {
                            if (i.symbol == "BTCUSD") {
                                CurrentEntry.push({
                                    price: i.takeProfit,
                                    color: "#3179F5",
                                    lineWidth: 1,
                                    lineStyle: 3,
                                    axisLabelVisible: true,
                                    title: `TP ${CryptoProfit(i.takeProfit, i.entryPrice, i.lot)}$`
                                })
                            } else if (i.symbol == "XAUUSD" || i.symbol == "ETHUSD") {
                                CurrentEntry.push({
                                    price: i.takeProfit,
                                    color: "#3179F5",
                                    lineWidth: 1,
                                    lineStyle: 3,
                                    axisLabelVisible: true,
                                    title: `TP ${calculateGold(i.takeProfit, i.entryPrice, i.lot)}$`
                                })
                            } else if (i.symbol == "EURJPY" || i.symbol == "GBPJPY" || i.symbol == "USDJPY" || i.symbol == "NZDJPY" || i.symbol == "AUDJPY") {
                                CurrentEntry.push({
                                    price: i.takeProfit,
                                    color: "#3179F5",
                                    lineWidth: 1,
                                    lineStyle: 3,
                                    axisLabelVisible: true,
                                    title: `TP ${calculateJPY(i.takeProfit, i.entryPrice, i.lot)}$`
                                })
                            }else if (i.symbol == "US30" || i.symbol == "NAS100") {
                                CurrentEntry.push({
                                    price: i.takeProfit,
                                    color: "#3179F5",
                                    lineWidth: 1,
                                    lineStyle: 3,
                                    axisLabelVisible: true,
                                    title: `TP ${calculateIndice(i.takeProfit, i.entryPrice, i.lot)}$`
                                })
                            } else {
                                CurrentEntry.push({
                                    price: i.takeProfit,
                                    color: "#3179F5",
                                    lineWidth: 1,
                                    lineStyle: 3,
                                    axisLabelVisible: true,
                                    title: `TP ${calculateProfit(i.takeProfit, i.entryPrice, i.lot)}$`
                                })
                            }
                        }
                        CurrentEntry.push({
                            price: i.entryPrice,
                            color: i.action === "Buy" ? "#3179F5" : "#FF3232",
                            lineWidth: 1,
                            lineStyle: 3,
                            axisLabelVisible: true,
                            title: `${i.action} ${i.lot}`
                        })
                    }
                })
            }
            if (Update.pendingOrders !== undefined && Update.pendingOrders.length !== 0) {
                Update.pendingOrders.map(i => {
                    console.log(i)
                    if (currentChart === i.symbol) {
                        if (i.stopLoss != null) {
                            CurrentPending.push({
                                price: i.stopLoss,
                                color: "#FF3232",
                                lineWidth: 1,
                                lineStyle: 3,
                                axisLabelVisible: true,
                                title: `SL`
                            })
                        }
                        if (i.takeProfit != null) {
                            CurrentPending.push({
                                price: i.takeProfit,
                                color: "#3179F5",
                                lineWidth: 1,
                                lineStyle: 3,
                                axisLabelVisible: true,
                                title: `TP`
                            })
                        }
                        CurrentPending.push({
                            price: i.entryPrice,
                            color: i.action === "Buy" ? "#3179F5" : "#FF3232",
                            lineWidth: 1,
                            lineStyle: 3,
                            axisLabelVisible: true,
                            title: `${i.action} ${i.lot}`
                        })
                    }
                })
            }
            setHasRun(true);
            setEntryLevel(CurrentEntry)
            setPending(CurrentPending)
        }


    }, [Update]);

    let priceLines = [];
    useEffect(() => {
        EntryLevel.forEach(lineConfig => {
            const hi = candleSeriesRef.current.createPriceLine(lineConfig);
            priceLines.push.apply(hi)
        });
        EntryPending.forEach(lineConfig => {
            const hi = candleSeriesRef.current.createPriceLine(lineConfig);
            priceLines.push(hi)
        });
    }, [EntryLevel, EntryPending]);

    useEffect(() => {
        setAccountId(Update.accountId)
        if (Update !== "") {
            if (Update.openPositions.length !== 0 && EntryLevel.length !== 0) {
            }
        }

    }, [Update]);



    /*-------Function to remove all price lines when chart changes-------- */
    useEffect(() => {
        // 
        const removePriceLines = () => {
            if (priceLines && priceLines.length > 0) {
                priceLines.forEach(priceLine => {
                    if (priceLine.remove) {
                        priceLine.remove();
                    }
                });
                priceLines = [];
            }
        };
        // Call the function to remove price lines
        removePriceLines();
        // This will run when `currentChart` changes
    }, [currentChart]);

    // useEffect(() => {
    //     candleSeriesRef.current.createPriceLine({
    //         price: 60000,
    //         color: 'rgba(53, 162, 74, 1)',
    //         lineWidth: 2,
    //         lineStyle: 3,
    //       title: 'buy order',
    //         draggable: true,
    //     });

    // }, []);
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    const handleChange = (event) => {
        setSettings({
            ...settings,
            [event.target.name]: event.target.checked,
        });
    };

    const handleColorChange = (color, setting) => {
        setColorSettings(prevSettings => ({
            ...prevSettings,
            [setting]: { ...prevSettings[setting], color: color.hex } // Update the color
        }));
    };

    const togglePicker = (setting) => {
        setColorSettings(prevSettings => ({
            ...prevSettings,
            [setting]: { ...prevSettings[setting], isOpen: !prevSettings[setting].isOpen } // Toggle visibility
        }));
    };

    const refs = useRef({});

    useEffect(() => {
        function handleClickOutside(event) {
            Object.keys(colorSettings).forEach(setting => {
                // Check if the click is outside the ref element
                if (colorSettings[setting].isOpen && refs.current[setting] && !refs.current[setting].contains(event.target)) {
                    setColorSettings(prevSettings => ({
                        ...prevSettings,
                        [setting]: { ...prevSettings[setting], isOpen: false }
                    }));
                }
            });
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [colorSettings]); // re-run if colorSettings changes

    return (
        <div className='main-chart'>
            <div className='chartTitle'>
                {/*---------------------------- TIMEFRAME -----------------------*/}
                <FormControl variant="standard">
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={currentTimeframe}
                        onChange={(e) => { setCurrentTimeframe(e.target.value) }}
                        label="Select Currency Pair"
                        input={<Input disableUnderline />}
                    >
                        <MenuItem value={"D"}>D</MenuItem>
                        <MenuItem value={"H4"}>H4</MenuItem>
                        <MenuItem value={"H1"}>H1</MenuItem>
                        <MenuItem value={"M30"}>M30</MenuItem>
                        <MenuItem value={"M15"}>M15</MenuItem>
                        <MenuItem value={"M5"}>M5</MenuItem>
                        <MenuItem value={"M1"}>M1</MenuItem>
                    </Select>
                </FormControl>
                {/*---------------------------- END -------------------------------*/}
                <div>
                    <IconButton onClick={handleClickOpen}>
                        <SettingsIcon />
                    </IconButton>
                    <React.Fragment>
                        <Dialog
                            fullScreen
                            open={open}
                            onClose={handleClose}
                            TransitionComponent={Transition}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>

                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">

                                        Setting
                                    </Typography>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={handleClose}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <List>
                                {Object.keys(settings).map((setting) => (
                                    <ListItem key={setting} secondaryAction={
                                        <Switch
                                            edge="end"
                                            onChange={handleChange}
                                            name={setting}
                                            checked={settings[setting]}
                                        />
                                    }>
                                        <ListItemText primary={setting.replace(/([A-Z])/g, ' $1').trim()} />
                                    </ListItem>
                                ))}
                                <ListItemButton>
                                    <ListItemText primary="Color Settings" />
                                </ListItemButton>

                                <Grid container spacing={2}>
                                    {Object.keys(colorSettings).map((setting) => (
                                        <Grid item xs={12} key={setting} container alignItems="center">
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: "10px" }}
                                                ref={el => refs.current[setting] = el} // Assign ref
                                            >
                                                <button onClick={() => togglePicker(setting)} style={{ backgroundColor: colorSettings[setting].color, width: "20px", height: "20px" }}>
                                                </button>
                                                <Grid>
                                                    <Typography variant="subtitle1" sx={{ marginLeft: '2px' }}>
                                                        {setting.replace(/([A-Z])/g, ' $1').trim()}
                                                    </Typography>
                                                </Grid>

                                                {colorSettings[setting].isOpen && (
                                                    <SketchPicker
                                                        color={colorSettings[setting].color}
                                                        onChangeComplete={(color) => handleColorChange(color, setting)}
                                                    />
                                                )}
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            </List>
                        </Dialog>
                    </React.Fragment>
                </div>
                {/*---------------------------- PAIR SYMBOL -----------------------*/}
                <FormControl variant="standard">
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={currentChart}
                        label="Select Currency Pair"
                        input={<Input disableUnderline />}
                    >
                        <MenuItem value={currentChart}>{currentChart}</MenuItem>

                    </Select>
                </FormControl>
                {/*------------------------------- END ---------------------------*/}

            </div>
            <div className='position'>
                <div className='btn-entry'>
                    <Button disabled={loading} onClick={() => {
                        sendOrder({ accountId: accountId, symbol: currentChart, lot, stopLoss: null, takeProfit: null, action: "Sell", entryPrice: null })
                    }} variant='contained' color='error'>
                        <span style={{ display: "flex" }}> <span>Sell</span>  <ArrowDropDownIcon /></span>
                    </Button>
                    <TextField
                        type="number"
                        value={lot}
                        onChange={(e) => {
                            setLot(e.target.value);
                        }}
                        onBlur={(e) => {
                            if (e.target.value < 0.01) {
                                setLot(0.01);
                            }
                        }}
                        className='lot-chart'
                        placeholder="Lot"
                        variant="standard" // Use the standard variant
                        InputProps={{
                            disableUnderline: true // Disable the underline to make it borderless
                        }}
                    />
                    <Button disabled={loading} onClick={() => {
                        sendOrder({ accountId: accountId, symbol: currentChart, lot, stopLoss: null, takeProfit: null, action: "Buy", entryPrice: null })
                    }} variant='contained'>
                        <span style={{ display: "flex", }}> <span>Buy</span>  <ArrowDropUpIcon /></span>
                    </Button>


                </div>
            </div>
            <div>
                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={2000}
                    onClose={handleSnackbarClose}
                    message={snackbar.message}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                />
            </div>
            <div ref={chartContainerRef} className="chart-container" />
        </div>
    );
};

function CryptoProfit(currentPrice, entryPrice, Lot) {
    return ((currentPrice - entryPrice) * (Lot * 0.5)).toFixed(2);
}
function calculateProfit(currentPrice, entryPrice, Lot) {
    return (((currentPrice - entryPrice) * 10000) * (Lot * 10)).toFixed(2);
}
function calculateGold(currentPrice, entryPrice, Lot) {
    return (((currentPrice - entryPrice) * 50) * Lot).toFixed(2);
}
function calculateIndice(currentPrice, entryPrice, Lot) {
    return (((currentPrice - entryPrice)) * (Lot * 10)).toFixed(2);
}
function calculateJPY(currentPrice, entryPrice, Lot) {
    return (((currentPrice - entryPrice) * 100) * (Lot * 10)).toFixed(2);
}