import React, { useEffect, useState, useRef } from 'react';
import './Orders.css'
import AddIcon from '@mui/icons-material/Add';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import axios from 'axios';
import Localbase from 'localbase'
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import { Drawer } from "vaul";
import soundFile from '../sounds/ok.wav';
import soundErr from "../sounds/err.wav"
import soundSuccess from "../sounds/ok.wav"
import { Typography, Slide, TextField } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export const Orders = ({ updatePair, sendDataToParent, ...props }) => {

    const playSoundErr = () => {
        const sound = new Audio(soundErr);
        sound.play()
            .catch(error => console.error("Error playing the sound:", error));
    };
    const playSoundSuccess = () => {
        const sound = new Audio(soundSuccess);
        sound.play()
            .catch(error => console.error("Error playing the sound:", error));
    };
    let URL;
    if (process.env.NODE_ENV === 'production') {
        URL = "https://multitradefx-58ae1ffb4e56.herokuapp.com";
    } else {
        // URL = "http://localhost:3000";
        URL = "https://multitradefx-58ae1ffb4e56.herokuapp.com";
    }
    let db = new Localbase('MultiverseFunding')
    const [AccountDetails, setAccountDetails] = useState(null)
    const [currentAccount, setcurrentAccount] = React.useState('');
    const [readyTransaction, setReadyTransaction] = React.useState({ id: 0, positionId: 0, type: "" })
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        db.collection('tradeAccount').get().then(users => {
            if (users.length !== 0) {
                setReadyTransaction({ id: users[0].id, positionId: readyTransaction.ticket })
            }
        })
    }, []);
    /*----------TOTAL PROFIT---------- */
    const [TotalPnL, setTotalPnL] = React.useState(0)
    /*----------END---------- */
    useEffect(() => {
        let state_Profit = 0;
        if (AccountDetails !== null && AccountDetails.openPositions.length !== "") {
            AccountDetails.openPositions.map(i => {
                state_Profit += Number(i.profit)
            })
        }
        setTotalPnL(state_Profit)

    }, [AccountDetails]);

    useEffect(() => {
        if (props.Update !== "") {
            setAccountDetails(props.Update)
        }
    }, [props.Update]);




    /*----------CONFIRM TO CLOSE POSITION---------- */
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleChange = (event) => {
        setcurrentAccount(event.target.value);
    };
    /*--------------------END-------------------------- */


    /*----------NOTIFIES SUCCESSFULL POSITION CLOSED---------- */
    const [openSnack, setOpenSnack] = React.useState(false);

    const handleClickSnack = () => {
        setOpenSnack(true);
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnack(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnack}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    /*-----------------------END------------------- */

    const [activeRowId, setActiveRowId] = useState(null);

    // Function to toggle actions for a specific row
    const toggleActions = (instrumentId) => {
        if (activeRowId === instrumentId) {
            // If clicking the same row again, hide the actions
            setActiveRowId(null);
        } else {
            // Else, show actions for the clicked row
            setActiveRowId(instrumentId);
        }
    };
    /*-----------------------COLORS TYPE------------------- */
    const typeColors = {
        Buy: "blue",
        Sell: "red",
        "Buy Limit": "blue",
        "Sell Limit": "red",
        "Buy Stop": "blue",
        "Sell Stop": "red"
    };
    /*-------------------------END-------------------------- */
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
    const [isDrawerOpenAll, setIsDrawerOpenAll] = React.useState(false);
    // Handler to close the drawer
    const handleCloseDrawer = () => setIsDrawerOpen(false);
    const [ticketNumber, setTicketNumber] = useState(null);

    // Handler to set the ticketNumber state
    const handleTicketSelect = (ticket) => {
        setTicketNumber(ticket);
    };
    // Handler to open the drawer
    const handleOpenDrawer = () => setIsDrawerOpen(true);
    const handleOpenDrawerAll = () => setIsDrawerOpenAll(true);
    const PositionDetails = ({ ticketNumber }) => {
        const position = AccountDetails.openPositions.find(position => position.positionId === ticketNumber);
        // Conditional rendering
        if (!position) {
            return <div></div>;
        }
        return (
            <Table sx={{ minWidth: 300, maxWidth: 500 }} aria-label="simple table">
                <TableBody>
                    <TableRow
                        className='positions-opened'
                    >
                        <TableCell className='positions-opened-symbol'>
                            {position.symbol} <span style={{ color: "blue" }}>{position.Type} {position.Lots}</span>
                            <div className='price-history'>
                                <p>{position.entryPrice}</p>
                                {/* <ArrowRightAltIcon />
                                <p>{position.closePrice}</p> */}
                            </div>

                        </TableCell>
                        <TableCell align="right" style={{ color: position.profit < 0 ? "red" : "blue", fontSize: "20px", fontWeight: "bold" }}>
                            {position.profit !== null ? position.profit.toFixed(2) : ""}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ borderBottom: 'none' }} className='positions-opened-symbol'>
                            <div className='Selected-position'>
                                <p>S/L:{position.stopLoss}</p>
                                <p>T/P:{position.takeProfit}</p>
                            </div>

                        </TableCell>
                        <TableCell style={{ borderBottom: 'none' }} align="right">
                            <div className='Selected-position'>
                                <p>Swap:{position.Swap}</p>
                                <p>{convertToHumanReadableTime(position.timestamp)}</p>
                            </div>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    };

    const PositionEdit = ({ ticketNumber }) => {
        const position = AccountDetails.openPositions.find(position => position.positionId === ticketNumber);
        // Conditional rendering
        if (!position) {
            return <div>Position not found.</div>;
        }
        return (
            <Table sx={{ minWidth: 300, maxWidth: 500 }} aria-label="simple table">
                <TableBody>
                    <TableRow
                        className='positions-opened'
                    >
                        <TableCell className='positions-opened-symbol'>
                            {position.symbol} <span style={{ color: "blue" }}>{position.Type} {position.Lots}</span>
                            <div className='price-history'>
                                <p>{position.entryPrice.toFixed(2)}</p>
                                {/* <ArrowRightAltIcon />
                                <p>{position.closePrice}</p> */}
                            </div>

                        </TableCell>
                        <TableCell align="right" style={{ color: position.profit < 0 ? "red" : "blue", fontSize: "20px", fontWeight: "bold" }}>
                            {position.profit !== null ? position.profit.toFixed(2) : ""}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    };

    const [partialLot, setpartialLot] = useState(0)
    const ClosePartial = ({ ticketNumber }) => {

        const position = AccountDetails.openPositions.find(position => position.positionId === ticketNumber);
        // Conditional rendering
        if (!position) {
            return <div>Position not found.</div>;
        }
        const formattedProfit = typeof position.profit === 'number' ? position.profit.toFixed(2) : position.profit;
        return (
            <div className='partials'>
                <Typography style={{ textAlign: "center" }}>Close partial</Typography>
                <div>
                    <Button onClick={() => { setpartialLot(position.lot * 0.25) }} style={{ height: "50px" }}>25%</Button>
                    <Button onClick={() => { setpartialLot(position.lot * 0.5) }} style={{ height: "50px" }}>50%</Button>
                    <TextField style={{ height: "50px" }} value={partialLot} onChange={(e) => { setpartialLot(e.target.value) }} />
                    <Button onClick={() => { setpartialLot(position.lot * 0.75) }} style={{ height: "50px" }}>75%</Button>
                    <Button onClick={() => { setpartialLot(position.lot * 0.9) }} style={{ height: "50px" }}>90%</Button>
                </div>
                <Button variant="contained" color="error" fullWidth sx={{ borderRadius: 0 }} onClick={()=>{
                    
                }}>
                    Close #{ticketNumber} {position.action} {partialLot != 0 ? partialLot : position.lot} at market with {partialLot != 0 ? formattedProfit * partialLot : formattedProfit}
                </Button>
            </div>
        );
    };


    const [open_Modify, setOpen_Modify] = React.useState(false);
    const handleClickOpen_Modify = () => {
        setOpen_Modify(true);
    };
    const handleClose_Modify = () => {
        setOpen_Modify(false);
    };
    const [sl, setSl] = useState(null)
    const [tp, setTp] = useState(null)
    return (
        <div className='main-order'>
            <div style={{ color: TotalPnL != 0 ? TotalPnL < 0 ? 'red' : 'blue' : "", textAlign: "center", fontSize: "20px", margin: "10px" }}>
                {Number(TotalPnL).toFixed(2)} USD
            </div>
            <div className='main-body-order'>
                <div className='acc-metric-order'>
                    <ul>
                        <li>Balance</li>
                        <li>Equity</li>
                    </ul>
                    <ul className='data-balance'>
                        <li>{AccountDetails !== null ? Number(AccountDetails.CurrentBalance).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00"}</li>
                        <li>{AccountDetails !== null ? Number(AccountDetails.equity).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.0"}</li>
                    </ul>
                </div>

                <div className='acc-positions-order'>
                    <TableContainer component={Paper} style={{ maxHeight: '70vh', overflow: 'auto' }}>
                        <Table sx={{ minWidth: 300, maxWidth: 500 }} aria-label="simple table">
                            <TableHead>
                                <TableRow style={{ height: "10px" }}>
                                    <TableCell style={{ padding: 0, paddingLeft: "5px" }}>Positions</TableCell>
                                    <TableCell style={{ padding: 0 }}></TableCell>
                                    <TableCell style={{ padding: 0, display: "flex", justifyContent: "flex-end" }}>
                                        <IconButton onClick={handleOpenDrawerAll}>
                                            <MoreHorizIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {AccountDetails !== null ? (
                                    AccountDetails.openPositions.map((instrument, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            className='positions-opened'

                                            onClick={() => {
                                                handleOpenDrawer()
                                                handleTicketSelect(instrument.positionId)
                                                setReadyTransaction({ id: readyTransaction.id, positionId: instrument.positionId, symbol: instrument.symbol });
                                                console.log(readyTransaction)
                                            }} // Assuming `instrument.id` is a unique identifier
                                        >
                                            <TableCell className='positions-opened-symbol'>
                                                {instrument.symbol}
                                                <div className='price-history'>
                                                    <p>{instrument.entryPrice}</p>
                                                    {/* <ArrowRightAltIcon />
                                                    <p>{instrument.closePrice}</p> */}
                                                </div>

                                            </TableCell>
                                            <TableCell style={{ color: typeColors[instrument.action] }} align="right">
                                                {instrument.action} {instrument.lot}
                                            </TableCell>
                                            <TableCell align="right" style={{ color: instrument.profit < 0 ? 'red' : 'blue' }}>
                                                {instrument.profit !== null ? Number((instrument.profit)).toFixed(2) : ""}
                                                {/* {console.log(instrument.profit)} */}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : ""}

                                {AccountDetails !== null ? (
                                    AccountDetails.pendingOrders.map((instrument, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            className='positions-opened'
                                            onClick={() => {
                                                handleTicketSelect(instrument.positionId)
                                                switch (instrument.action) {
                                                    case "Buy":
                                                    case "Sell":
                                                        setReadyTransaction({ id: readyTransaction.id, positionId: instrument.positionId, type: "market" });
                                                        break;
                                                    case "Buy Limit":
                                                    case "Sell Limit":
                                                    case "Buy Stop":
                                                    case "Sell Stop":
                                                        setReadyTransaction({ id: readyTransaction.id, positionId: instrument.positionId, type: "pending" });
                                                        break;
                                                    default:
                                                        break;
                                                }
                                                handleOpenDrawer()
                                            }}
                                        >
                                            <TableCell className='positions-opened-symbol'>
                                                {instrument.symbol}
                                                <div className='price-history'>
                                                    <p>{instrument.entryPrice}</p>
                                                </div>

                                            </TableCell>
                                            <TableCell style={{ color: typeColors[instrument.action] }} align="right">
                                                {instrument.action} {instrument.lot}
                                            </TableCell>
                                            <TableCell align="right" style={{ color: instrument.profit < 0 ? 'red' : 'blue' }}>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    ""
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div>
                    <Snackbar
                        open={openSnack}
                        autoHideDuration={500}
                        onClose={handleCloseSnack}
                        message="Position Closed successFully"
                        action={action}
                    />
                    <Drawer.Root open={isDrawerOpen} onOpenChange={setIsDrawerOpen} shouldScaleBackground>
                        <Drawer.Portal>
                            <Drawer.Overlay className="fixed inset-0 bg-black/40" />
                            <Drawer.Content className="bg-zinc-100 flex flex-col rounded-t-[10px] h-[50%] mt-24 fixed bottom-0 left-0 right-0">
                                <div className="p-4 bg-white rounded-t-[10px] flex-1">
                                    <div className="mx-auto w-12 h-1.5 flex-shrink-0 rounded-full bg-zinc-300 mb-8" />
                                    <div className="max-w-md mx-auto">
                                        <PositionDetails ticketNumber={ticketNumber} />
                                        <div className='actionSheet-Trade'>
                                            <Button disabled={loading} onClick={
                                                async () => {
                                                    try {
                                                        if (readyTransaction.id !== 0 && readyTransaction.positionId !== undefined && readyTransaction.type !== "") {
                                                            const close_position = `${URL}/api/OrderClose`;
                                                            const close_pending = `${URL}/api/close-pending-orders`;
                                                            const payload = {
                                                                accountId: readyTransaction.id,
                                                                positionId: readyTransaction.positionId,
                                                            };
                                                            if (readyTransaction.type != "pending") {
                                                                setLoading(true)
                                                                const response = await axios.post(close_position, payload);
                                                                if (response.data.success) {
                                                                    handleClickSnack()
                                                                    playSoundSuccess()
                                                                    setTimeout(() => {
                                                                        setIsDrawerOpen(false);
                                                                        setLoading(false)
                                                                    }, 1000);
                                                                }
                                                            } else {
                                                                setLoading(true)
                                                                const response = await axios.post(close_pending, payload);
                                                                if (response.data.success) {
                                                                    handleClickSnack()
                                                                    playSoundSuccess()
                                                                    setTimeout(() => {
                                                                        setIsDrawerOpen(false);
                                                                        setLoading(false)
                                                                    }, 1000);
                                                                }
                                                            }
                                                        }
                                                    } catch (error) {
                                                        console.error('Failed to close order:', error);
                                                        playSoundErr()
                                                        setLoading(false)
                                                    }

                                                }} style={{ color: "red" }}>Close Position</Button>
                                            <Button onClick={() => { setIsDrawerOpen(false); handleClickOpen_Modify() }}>Modify Position</Button>
                                            <Button onClick={() => {
                                                updatePair(readyTransaction.symbol)
                                                sendDataToParent(1)
                                            }}>Chart</Button>
                                        </div>
                                    </div>
                                </div>
                            </Drawer.Content>
                        </Drawer.Portal>
                    </Drawer.Root>

                    <Drawer.Root open={isDrawerOpenAll} onOpenChange={setIsDrawerOpenAll} shouldScaleBackground>
                        <Drawer.Portal>
                            <Drawer.Overlay className="fixed inset-0 bg-black/40" />
                            <Drawer.Content className="bg-zinc-100 flex flex-col rounded-t-[10px] h-[50%] mt-24 fixed bottom-0 left-0 right-0">
                                <div className="p-4 bg-white rounded-t-[10px] flex-1">
                                    <div className="mx-auto w-12 h-1.5 flex-shrink-0 rounded-full bg-zinc-300 mb-8" />
                                    <div className="max-w-md mx-auto">
                                        <div className='actionSheet-Trade'>
                                            <Button disabled={loading} onClick={
                                                async () => {
                                                    try {
                                                        console.log(readyTransaction.id)
                                                        if (readyTransaction.id !== 0) {
                                                            const close_all_position = `${URL}/api/CloseAll`;
                                                            const payload = {
                                                                accountId: readyTransaction.id,
                                                            };
                                                            setLoading(true)
                                                            const response = await axios.post(close_all_position, payload);
                                                            if (response.data.success) {
                                                                handleClickSnack()
                                                                playSoundSuccess()
                                                                setTimeout(() => {
                                                                    setIsDrawerOpenAll(false);
                                                                    setLoading(false)
                                                                }, 2000);
                                                            }
                                                        }
                                                    } catch (error) {
                                                        console.error('Failed to close order:', error);
                                                        playSoundErr()
                                                        setLoading(false)
                                                    }

                                                }} style={{ color: "red" }}>Close All Positions</Button>
                                            <Button onClick={() => {
                                                setIsDrawerOpenAll(false);
                                            }}>Cancel</Button>
                                        </div>
                                    </div>
                                </div>
                            </Drawer.Content>
                        </Drawer.Portal>
                    </Drawer.Root>

                    <React.Fragment>
                        <Dialog
                            fullScreen
                            open={open_Modify}
                            onClose={handleClose_Modify}
                            TransitionComponent={Transition}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        <PositionEdit ticketNumber={ticketNumber} />
                                    </Typography>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={handleClose_Modify}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <div>
                                <div className='Market-Execution'>
                                    <div className='sl-tp-upTodate'>

                                        <div className='sl'>
                                            <p>Stop Loss</p>
                                            <div>
                                                <RemoveIcon />
                                                <input
                                                    value={sl}
                                                    onChange={(e) => { setSl(e.target.value) }}
                                                    placeholder='not set'
                                                />
                                                <AddIcon />
                                            </div>

                                        </div>
                                        <div className='tp'>
                                            <p>Take Profit</p>
                                            <div>
                                                <RemoveIcon />
                                                <input
                                                    value={tp}
                                                    onChange={(e) => { setTp(e.target.value) }}
                                                    placeholder='not set'
                                                />
                                                <AddIcon />
                                            </div>

                                        </div>
                                    </div>
                                    <div className='price-btn'>
                                    </div>
                                    <div className='btn-entry-pending'>
                                        <Button onClick={(e) => {
                                            e.preventDefault()
                                            if (readyTransaction.positionId !== 0 && readyTransaction.id !== 0) {
                                                if (sl !== null || tp !== null) {
                                                    axios.post(`${URL}/api/edit-order`, {
                                                        accountId: readyTransaction.id, positionId: readyTransaction.positionId, stopLoss: Number(sl), takeProfit: Number(tp)
                                                    })
                                                        .then(data => {
                                                            if (data.data.success) {
                                                                handleClickSnack()
                                                            }
                                                        })
                                                        .catch(err => {
                                                            console.log(err)
                                                        })
                                                }
                                            }

                                        }} variant='contained' >
                                            <span style={{ display: "flex" }}> <span>Modify Order</span> </span>
                                        </Button>

                                    </div>
                                    <ClosePartial ticketNumber={ticketNumber} />



                                </div>
                            </div>
                        </Dialog>
                    </React.Fragment>
                </div>
            </div>
        </div>
    );
};

function convertToHumanReadableTime(isoString) {
    const date = new Date(isoString);
    const optionsDate = { year: 'numeric', month: 'long', day: 'numeric' };
    const optionsTime = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true };
    const humanReadableCustom = `${date.toLocaleDateString('en-US', optionsDate)} at ${date.toLocaleTimeString('en-US', optionsTime)}`;
    return humanReadableCustom;
}