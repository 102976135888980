import React, { useState } from 'react';
import {
    Container,
    TextField,
    Button,
    Typography,
    FormControl,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

// Array of currency pairs
const currencyPairs = [
    'AUDNZD', 'AUDUSD', 'CHFJPY', 'AUDCAD', 'AUDJPY', 'AUDCHF', 'XAUUSD', 'XAUEUR', 'EURUSD',
    'EURJPY', 'GBPUSD', 'BTCUSD', 'DOGEUSD', 'ETHUSD', 'GBPJPY', 'USDJPY', 'EURCHF', 'EURGBP',
    'EURNZD', 'GBPCHF', 'GBPAUD', 'GBPCAD', 'NZDCAD', 'NZDCHF', 'NZDJPY', 'NZDUSD', 'USDCAD',
    'USDCHF', 'XAGUSD', 'US30', 'NAS100', 'EURNOK', 'EURTRY', 'ADAUSD', 'USOIL'
];

const LotSizeCalculator = () => {
    const [currencyPair, setCurrencyPair] = useState('');
    const [entryPrice, setEntryPrice] = useState('');
    const [stopLoss, setStopLoss] = useState('');
    const [riskAmount, setRiskAmount] = useState('');
    const [lotSize, setLotSize] = useState('');

    const calculateLotSize = () => {
        if (entryPrice && stopLoss && riskAmount) {
            console.log(currencyPair)
            if (currencyPair == "BTCUSD") {
                setLotSize(calculateLotSizeForCryptoProfit(riskAmount, entryPrice, stopLoss))
            } else if (currencyPair == "EURJPY" || currencyPair == "GBPJPY" || currencyPair == "USDJPY" || currencyPair == "NZDJPY" || currencyPair == "XAGUSD" || currencyPair == "AUDJPY") {
                setLotSize(calculateLotSizeForProfitJPY(riskAmount, entryPrice, stopLoss))
            } else if (currencyPair == "XAUUSD" || currencyPair == "ETHUSD") {
                setLotSize(calculateLotSizeForProfitGold(riskAmount, entryPrice, stopLoss))
            } else if (currencyPair == "US30" || currencyPair == "NAS100") {
                setLotSize(calculateLotSizeForProfitIndice(riskAmount, entryPrice, stopLoss))
            } else {
                setLotSize(calculateLotSizeForProfit(riskAmount, entryPrice, stopLoss))
            }

        } else {
            setLotSize('Please fill in all fields');
        }
    };


    function calculateLotSizeForProfit(profit, currentPrice, entryPrice) {
        return Math.abs(profit / (((currentPrice - entryPrice) * 10000) * 10)).toFixed(2);
    }

    /*-----------------------CALCULATE PROFIT Gold------------------------- */
    function calculateLotSizeForProfitGold(profit, currentPrice, entryPrice) {
        return Math.abs(profit / ((currentPrice - entryPrice) * 50)).toFixed(2);
    }

    function calculateLotSizeForProfitIndice(profit, currentPrice, entryPrice) {
        return Math.abs(profit / ((currentPrice - entryPrice) * 10)).toFixed(2);
    }

    function calculateLotSizeForProfitJPY(profit, currentPrice, entryPrice) {
        return Math.abs(profit / (((currentPrice - entryPrice) * 100) * 10)).toFixed(2);
    }

    /*-----------------------CALCULATE PROFIT CRYPTO------------------------- */
    function calculateLotSizeForCryptoProfit(profit, currentPrice, entryPrice) {
        return Math.abs(profit / ((currentPrice - entryPrice) * 0.5)).toFixed(2);
    }

    return (
        <Container maxWidth="sm">
            <Typography variant="h6" gutterBottom>
                Forex Lot Size Calculator
            </Typography>
            <FormControl fullWidth margin="normal">
                <Autocomplete
                    options={currencyPairs}
                    value={currencyPair}
                    onChange={(e, newValue) => setCurrencyPair(newValue)}
                    renderInput={(params) => <TextField {...params} label="Currency Pair" />}
                />
            </FormControl>
            <TextField
                label="Entry Price"
                fullWidth
                margin="normal"
                type="number"
                value={entryPrice}
                onChange={(e) => setEntryPrice(e.target.value)}
            />
            <TextField
                label="Stop Loss"
                fullWidth
                margin="normal"
                type="number"
                value={stopLoss}
                onChange={(e) => setStopLoss(e.target.value)}
            />
            <TextField
                label="Risk Amount(USD)"
                fullWidth
                margin="normal"
                type="number"
                value={riskAmount}
                onChange={(e) => setRiskAmount(e.target.value)}
            />
            <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={calculateLotSize}
                style={{ marginTop: '16px' }}
            >
                Calculate Lot Size
            </Button>
            {lotSize && (
                <Typography variant="h6" gutterBottom style={{ marginTop: '16px' }}>
                    Calculated Lot Size: {lotSize}
                </Typography>
            )}
        </Container>
    );
};

export default LotSizeCalculator;
