import React from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
const CardContainer = styled(Box)({
  backgroundColor: '#2e2e2e',
  color: 'white',
  padding: '16px',
  borderRadius: '8px',
  textAlign: 'center',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  margin:'25px 0px' 
});

const ImageContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '16px',
});

const PromotionalCard = () => {
  return (
    <CardContainer>
      <Typography variant="h6" gutterBottom>
        Start New Account
      </Typography>
      <ImageContainer>
        <AttachMoneyIcon style={{ fontSize: '100px', color: '#ffd700' }} />
      </ImageContainer>
      <Button variant="contained" color="primary" style={{ backgroundColor: '#b58e57' }}>
        GET FUNDED NOW $
      </Button>
    </CardContainer>
  );
};

export default PromotionalCard;
