import React, { useState, useEffect } from 'react';
import './account.css'
import Paper from '@mui/material/Paper';
import { Button, TextField, Grid, Typography, IconButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import Localbase from 'localbase'
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Input } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LotSizeCalculator from './LotSizeCalculator'
export const Accounts = ({ onLoginStatus, ...props }) => {

    let URL;

    if (process.env.NODE_ENV === 'production') {
        URL = "https://multitradefx-58ae1ffb4e56.herokuapp.com/api";
    } else {
        // URL = "http://localhost:3000/api";
        URL = "https://multitradefx-58ae1ffb4e56.herokuapp.com/api"; 
    }
    let dashboardUrl="https://www.multiverse-funding.com/api"
    let db = new Localbase('MultiverseFunding')
    const [AccountDetails, setAccountDetails] = useState(null)
    const [loadinglogin, setloadinglogin] = useState(false)
    const [loginState, setLoginState] = useState("")
    const [accountID, setAccountID] = useState("");
    const [password, setPassword] = useState('');
    const [loginFeedback, setLoginFeedback] = useState("")
    const [CurrentAccount, setCurrentAccount] = useState("")
    /*-------------------LOGIN/SIGN UP STATUS APPP----------------*/
    useEffect(() => {
        db.collection('user').get().then(users => {
            if (users.length === 0) {
                setLoginState("login")
            } else {
                setLoginState("logged in")
                // setAccountDetails(users[0])
                axios.post(`${dashboardUrl}/find-user-by-email-for-id`,{email:users[0].email})
                .then(res=>{
                    setAccountDetails(res.data[0])
                })
                .catch(err=>{
                    console.log(err)
                    setAccountDetails(users[0])
                })
            }
        })
        db.collection('tradeAccount').get().then(users => {
            if (users.length !== 0) {
                setCurrentAccount(users[0].id)
            }
        })
    }, []);
    /*-----------------------------END-----------------------------*/

    /*----------------------------------------------------------------HANDLE MT4 LOGIN-------------------------------------------------------------*/
    const handleLogin = async () => {
        try {
            if (accountID !== undefined && password.trim() !== '') {
                setloadinglogin(true)
                const response = await axios.post(`${URL}/login`, { email: accountID, password: password })
                if (response.data.success === true) {
                    setloadinglogin(false)
                    db.collection('user').add(response.data.user)
                    setAccountDetails(response.data.user)
                    setLoginState("logged in")
                    onLoginStatus(1)
                    if (response.data.user.accountIDs.length !== 0) {
                        db.collection('tradeAccount').add({ id: response.data.user.accountIDs[0] })
                    }
                } else {
                    setLoginFeedback(response.data.err)
                    handleClickSnack()
                    setloadinglogin(false)
                }
            }
        } catch (error) {
            setloadinglogin(false)
            alert("something went wrong")
        }
    };
    /*---------------------------------------------------------------------END--------------------------------------------------------------------- */


    /*--------------------------------------------------HANDLE MT4 LOGOUT--------------------------------------------------*/
    const handleLogout = async () => {
        try {
            await db.collection('user').delete();
            await db.collection('tradeAccount').delete().then(e => {
                window.location.reload();
            }).catch(err => { alert("failed to logout") })

            setLoginState("login");


        } catch (error) {
            setloadinglogin(false);
            alert("Something went wrong");
        }
    };
    /*----------------------------------------------------------END--------------------------------------------------------- */


    /*--------------------ERROR HANDLER---------------------- */
    const [openSnack, setOpenSnack] = React.useState(false);

    const handleClickSnack = () => {
        setOpenSnack(true);
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnack(false);
    };
    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="error"
                onClick={handleCloseSnack}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    /*-----------------------END------------------- */

    return (
        <div className='main-acc-container'>
            {loginState === "login" ?

                <div>
                    <Grid style={{ margin: "25px" }}>
                        <Grid item xs={10} sm={6} md={4}>
                            <Paper style={{ padding: '20px' }}>
                                <Typography variant="h5" align="center" gutterBottom>
                                    Login
                                </Typography>
                                <form onSubmit={(e) => { e.preventDefault(); handleLogin(); }}>
                                    <TextField
                                        variant="outlined"
                                        label="Email"
                                        fullWidth
                                        margin="normal"
                                        value={accountID}
                                        onChange={(e) => setAccountID(e.target.value)}
                                    />
                                    <TextField
                                        variant="outlined"
                                        label="Password"
                                        type="password"
                                        fullWidth
                                        margin="normal"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        type="submit"
                                        style={{ marginTop: '20px' }}
                                    >
                                        {loadinglogin === false ? "Login" : <CircularProgress color="inherit" />}

                                    </Button>
                                </form>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Snackbar
                        open={openSnack}
                        autoHideDuration={2000}
                        onClose={handleCloseSnack}
                        action={action}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        severity="success">
                        <Alert
                            onClose={handleCloseSnack}
                            severity="error"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >{loginFeedback}</Alert>
                    </Snackbar>
                </div>
                : <div className='loggedin'>
                    <div>
                        <Button size="small" onClick={handleLogout}>Logout</Button>
                        <FormControl variant="standard">
                            {AccountDetails !== null ?
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={CurrentAccount}
                                    onChange={(e) => {
                                        db.collection('tradeAccount').doc({ id: CurrentAccount }).update({
                                            id: e.target.value
                                        }).then(e => {
                                            window.location.reload();
                                        }).catch(err => { alert("failed to logout") })
                                        setCurrentAccount(e.target.value)
                                        onLoginStatus(1)
                                    }}
                                    label="Select Currency Pair"
                                    input={<Input disableUnderline />}
                                >
                                    {AccountDetails.accountIDs.map((id, index) => (
                                        <MenuItem key={index} value={id}>{id}</MenuItem>
                                    ))}
                                </Select>
                                : ""}

                        </FormControl>
                        <br/>
                        <LotSizeCalculator/>
                    </div>

                </div>}
        </div>
    );
};
